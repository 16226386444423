import React, { memo, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Clear, Done } from "@mui/icons-material";
import { Box, IconButton, Input, Stack, Typography } from "@mui/material";
import clsx from "clsx";
import { isOnlyLink, StorageUtil } from "utils";
import LinkPreview from "./LinkPreview";
import { saveMessageInQueue } from "../ConversationContext";
import ChatTypography from "./ChatTypography";
import { replaceId2Name, replaceName2Id } from "utils/message.utils";
import { getCreatedTimeText, getMentionMembers, useMessageStyle } from "./ChatItem.helper";
import FullEmojiLayout from "../MessengerChatInput/FullEmojiLayout";
import { useInputEvent } from "hooks";
import { AppAvatar } from "components";

const MessengerPeople = ({ message, isMine, isEditing, setIsEditing }) => {
  const hasExternalLink = isOnlyLink(message.content);
  const isAvatar = Boolean(message.isAvatar);

  return (
    <>
      {isMine ? (
        <MyMessage
          message={message}
          hasExternalLink={hasExternalLink}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
      ) : (
        <OtherMessage message={message} hasExternalLink={hasExternalLink} isAvatar={isAvatar} />
      )}
    </>
  );
};

export default memo(MessengerPeople);

export const MyMessage = ({ message, isEditing, hasExternalLink, setIsEditing, customStyles }) => {
  const classes = useStyles();
  const messengerClasses = useMessageStyle();
  const prefixKey = StorageUtil.getCurrentPrefixKey();

  const [modifiedMessage, setModifiedMessage] = useState();
  const [defaultContent, setDefaultContent] = useState();
  const [messageContent, setMessageContent] = useState();
  const [mentionList, setMentionList] = useState([]);

  const handleCancel = () => setIsEditing(false);

  const handleEditMessage = async content => {
    if (content !== defaultContent) {
      await saveMessageInQueue({
        prefixKey,
        groupId: message.groupId,
        threadId: message.threadId,
        content: replaceName2Id(content, mentionList),
        parentId: message.sourceId,
        mentionIds: message.mentions,
      });

      setDefaultContent(content);
      setMessageContent(content);
      setModifiedMessage(Date.now());
    }
    setIsEditing(false);
  };

  useEffect(() => {
    if (modifiedMessage && modifiedMessage > message.modified) return;

    let isMounted = true;
    replaceId2Name(message.content, mentionList, false).then(displayContent => {
      if (isMounted) {
        setDefaultContent(displayContent);
        setMessageContent(displayContent);
      }
    });
    getMentionMembers(prefixKey, message.mentions).then(mentionMembers => {
      if (isMounted) {
        setMentionList(mentionMembers);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [message]);

  return (
    <Stack
      direction="column"
      className={clsx(
        messengerClasses.messengerRoot,
        messengerClasses.myMessage,
        {
          [classes.previewLink]: hasExternalLink,
        },
        customStyles,
      )}
    >
      {isEditing ? (
        <EditMessageContent
          classes={classes}
          defaultContent={defaultContent}
          onSubmit={handleEditMessage}
          onCancel={handleCancel}
        />
      ) : (
        <>
          <ChatTypography
            messageContent={message.content}
            mentionList={mentionList}
            className={messengerClasses.spacing}
            messageId={message.id}
          />

          {hasExternalLink && (
            <LinkPreview
              message={{
                ...message,
                content: messageContent,
              }}
            />
          )}
        </>
      )}
    </Stack>
  );
};

const OtherMessage = ({ message, hasExternalLink, isAvatar }) => {
  const classes = useStyles();
  const messengerClasses = useMessageStyle();
  const msgCreatedTime = getCreatedTimeText(message);

  return (
    <Stack direction="row" spacing={2}>
      <AppAvatar avatarId={message.avatarId} size={40} className={clsx(!isAvatar && messengerClasses.hiddenAvatar)} />

      <Box>
        {msgCreatedTime && (
          <Typography className={clsx("ellipsis", messengerClasses.createdTimeText)}>{msgCreatedTime}</Typography>
        )}

        <Stack
          direction="column"
          className={clsx(messengerClasses.messengerRoot, {
            [classes.previewLink]: hasExternalLink,
          })}
        >
          <ChatTypography
            messageContent={message.content}
            mentions={message.mentions}
            className={clsx(messengerClasses.spacing)}
            messageId={message.id}
          />

          {hasExternalLink && <LinkPreview message={message} />}
        </Stack>
      </Box>
    </Stack>
  );
};

const EditMessageContent = ({ defaultContent, onSubmit, onCancel }) => {
  const classes = useStyles();
  const { inputRef, inputValue, insertContentInput, updateContentInput } = useInputEvent();

  const handleChangeContent = event => updateContentInput(event.target.value);

  const handleUpdateContent = value => insertContentInput(value);

  const handleSubmit = () => onSubmit(inputValue.trim());

  useEffect(() => {
    updateContentInput(defaultContent);
  }, []);

  return (
    <Box className={classes.editMessageRoot}>
      <Input
        classes={{
          root: classes.editMessageInputRoot,
          input: classes.editMessageInput,
        }}
        inputRef={inputRef}
        onChange={handleChangeContent}
        disableUnderline
        multiline
      />

      <Box className={classes.editActionBox}>
        <FullEmojiLayout onSelect={handleUpdateContent} />
        <Box>
          <IconButton onClick={onCancel}>
            <Clear />
          </IconButton>
          <IconButton color="primary" onClick={handleSubmit} disabled={inputValue === defaultContent}>
            <Done />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  previewLink: {
    width: 300,
    maxWidth: "100%",
  },

  editMessageRoot: {
    backgroundColor: "#F3F3F5",
  },

  editMessageInputRoot: {
    width: "100%",
    padding: "16px 30px",
  },

  editMessageInput: {
    width: 520,
    maxWidth: "100%",
  },

  editActionBox: {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 16px",
    paddingTop: 0,
  },
});
