import { useCallback, useEffect, useMemo, useState } from "react";
import { AppConstant, KeyConstant, SystemConstant } from "const";
import { StorageUtil, toCamel } from "utils";
import { useSelector } from "react-redux";
import { BranchSelectors, GroupInfoSelectors, SystemSelectors } from "redux-store";
import { getInteractor } from "services/local.service";
import debounce from "lodash/debounce";
import { createSelector } from "reselect";
import isEqual from "lodash/isEqual";

const memoizedReduxState = createSelector(
  [
    SystemSelectors.isSystemSynchronizing,
    BranchSelectors.getSelectedBranch,
    state => state.conversationRedux.isUpdateViewMode,
    state => state.groupInfoRedux.newGroupId,
  ],
  (isSynchronizing, selectedBranch, isUpdateViewMode, newGroupId) => {
    return {
      isSynchronizing,
      selectedBranch,
      isUpdateViewMode,
      newGroupId,
    };
  },
);

const updateGroupState = createSelector(
  [
    GroupInfoSelectors.getDeleteGroup,
    GroupInfoSelectors.getUpdatingGroup,
    state => state.groupInfoRedux.updateGroupCategory,
  ],
  (deleteGroup, updatingGroupData, updateGroupCategory) => ({ deleteGroup, updatingGroupData, updateGroupCategory }),
);

export const LIMIT_DISPLAY = 10;
const useConversation = type => {
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);

  const { isSynchronizing, selectedBranch, isUpdateViewMode, newGroupId } = useSelector(memoizedReduxState);
  const { deleteGroup, updatingGroupData, updateGroupCategory } = useSelector(updateGroupState);

  const [limit, setLimit] = useState(LIMIT_DISPLAY);
  const [totalItems, setTotalItems] = useState(0);
  const [groupList, setGroupList] = useState([]);

  const SQL_CONDITION = useMemo(() => {
    return getCondition(type);
  }, [type]);

  const getTotalGroup = async () => {
    let countItems = 0;
    switch (type) {
      case AppConstant.CONVERSATION_CATEGORY.unread:
        countItems = await getInteractor(prefixKey).LocalGroupService.count(
          {
            account_id: accountId,
            branch_id: branchId,
            state: SystemConstant.STATE.active,
          },
          {
            conditionQuery: "'group'.unread > 0",
          },
        );
        break;

      case AppConstant.CONVERSATION_CATEGORY.personal:
        countItems = await getInteractor(prefixKey).LocalGroupService.count(
          {
            account_id: accountId,
            branch_id: branchId,
            state: SystemConstant.STATE.active,
            ...SQL_CONDITION,
          },
          {
            conditionQuery: CONDITION_PERSONAL_QUERY,
          },
        );
        break;

      default:
        countItems = await getInteractor(prefixKey).LocalGroupService.count({
          account_id: accountId,
          branch_id: branchId,
          state: SystemConstant.STATE.active,
          ...SQL_CONDITION,
        });
        break;
    }

    setTotalItems(countItems);
  };

  const searchConversation = async (limitNum = LIMIT_DISPLAY) => {
    setLimit(limitNum);
    await getTotalGroup();
    let conversationList = [];
    switch (type) {
      case AppConstant.CONVERSATION_CATEGORY.unread:
        conversationList = await getInteractor(prefixKey).LocalGroupService.searchGroupByCondition({
          limit: limitNum,
          offset: 0,
          account_id: accountId,
          branch_id: branchId,
          state: SystemConstant.STATE.active,
          extendQuery: {
            conditionQuery: "'group'.unread > 0",
          },
        });

        conversationList = toCamel(conversationList);
        break;

      case AppConstant.CONVERSATION_CATEGORY.personal:
        conversationList = await getInteractor(prefixKey).LocalGroupService.searchGroupByCondition({
          limit: limitNum,
          offset: 0,
          account_id: accountId,
          branch_id: branchId,
          ...SQL_CONDITION,
          extendQuery: {
            conditionQuery: CONDITION_PERSONAL_QUERY,
          },
        });
        break;

      default:
        conversationList = await getInteractor(prefixKey).LocalGroupService.searchGroupByCondition({
          limit: limitNum,
          offset: 0,
          account_id: accountId,
          branch_id: branchId,
          ...SQL_CONDITION,
        });
        break;
    }

    if (Array.isArray(conversationList)) {
      setGroupList(conversationList);
    }
  };

  const getConversation = useCallback(debounce(searchConversation, AppConstant.DEBOUNCE_TIME), [prefixKey]);

  // Handle update group info: groupName, avatar, status
  const handleChangingGroup = async () => {
    // Update group
    const group = (await getInteractor(prefixKey).LocalGroupService.get(updatingGroupData.id)) || updatingGroupData;

    const selectedIndex = groupList.findIndex(item => item.id === updatingGroupData.id);
    const isChangeContent = Boolean(
      updatingGroupData.groupName || updatingGroupData.avatarId || updatingGroupData.status >= 0,
    );

    // Updating group
    if (isChangeContent && selectedIndex >= 0) {
      const newGroup = { ...group, ...updatingGroupData };

      const newGroupList = [...groupList];
      newGroupList[selectedIndex] = newGroup;
      setGroupList(newGroupList);
    }
  };

  const handleNewGroup = async () => {
    const localGroup = await getInteractor(prefixKey).LocalGroupService.get(newGroupId);
    if (localGroup.groupType === SQL_CONDITION.group_type) {
      const newGroupList = [localGroup, ...groupList];
      setGroupList(newGroupList);
    }
  };

  const handleUpdateGroupCategory = async updateGroup => {
    await getTotalGroup();

    let newGroupList = groupList;
    const displayGroup = groupList.find(item => item.id === updateGroup.id);
    const localGroup = await getInteractor(prefixKey).LocalGroupService.get(updateGroup.id);
    if (false === Boolean(localGroup && localGroup.id)) return;

    switch (type) {
      case AppConstant.CONVERSATION_CATEGORY.unread:
        const isAddUnread = !displayGroup && localGroup.unread > 0;
        const isRemoveUnread = displayGroup && localGroup.unread === 0;
        if (isAddUnread || isRemoveUnread) getConversation(limit);
        break;

      case AppConstant.CONVERSATION_CATEGORY.favorite:
        const isAddFavorite =
          !displayGroup && localGroup.userType === SystemConstant.GROUP_USER_TYPE.favorite && localGroup.unread === 0;
        const isRemoveFavorite =
          displayGroup && (localGroup.userType === SystemConstant.GROUP_USER_TYPE.none || localGroup.unread > 0);
        if (isAddFavorite || isRemoveFavorite) getConversation(limit);
        break;

      default:
        const isRemove =
          displayGroup && (localGroup.userType === SystemConstant.GROUP_USER_TYPE.favorite || localGroup.unread > 0);
        const isAdd = !isRemove && localGroup.groupType === type;

        if (isAdd || isRemove) getConversation(limit);
        break;
    }

    if (!isEqual(groupList, newGroupList)) setGroupList(newGroupList);
  };

  useEffect(() => {
    getConversation(LIMIT_DISPLAY);
  }, [selectedBranch]);

  useEffect(() => {
    if (!isSynchronizing) {
      getConversation(limit);
    }
  }, [isSynchronizing, isUpdateViewMode]);

  useEffect(() => {
    if (deleteGroup && groupList.length > 0) {
      getTotalGroup();

      const deletedIndex = groupList.findIndex(item => item.id === deleteGroup.groupId);
      if (deletedIndex >= 0) setGroupList(groupList.filter(item => item.id !== deleteGroup.groupId));
    }
  }, [deleteGroup]);

  useEffect(() => {
    if (updateGroupCategory) {
      handleUpdateGroupCategory(updateGroupCategory);
    }
  }, [updateGroupCategory]);

  useEffect(() => {
    const isExist = groupList.findIndex(item => item.id === updatingGroupData?.id) >= 0;

    if (isExist) handleChangingGroup();
  }, [updatingGroupData]);

  useEffect(() => {
    if (newGroupId) handleNewGroup();
  }, [newGroupId]);

  return {
    groupList,
    totalItems,
    getConversation,
  };
};

export default useConversation;

const getCondition = type => {
  switch (type) {
    case AppConstant.CONVERSATION_CATEGORY.favorite:
      return { user_type: SystemConstant.GROUP_USER_TYPE.favorite, unread: 0 };

    case AppConstant.CONVERSATION_CATEGORY.personal:
      return {
        group_type: SystemConstant.GROUP_CHAT_TYPE.personal,
        unread: 0,
        user_type: SystemConstant.GROUP_USER_TYPE.none,
      };

    case AppConstant.CONVERSATION_CATEGORY.group:
      return {
        group_type: SystemConstant.GROUP_CHAT_TYPE.group,
        unread: 0,
        user_type: SystemConstant.GROUP_USER_TYPE.none,
      };

    case AppConstant.CONVERSATION_CATEGORY.channel:
      return {
        group_type: SystemConstant.GROUP_CHAT_TYPE.channel,
        unread: 0,
        user_type: SystemConstant.GROUP_USER_TYPE.none,
      };

    default:
      return {
        group_type: SystemConstant.GROUP_CHAT_TYPE.personal,
        unread: 0,
        user_type: SystemConstant.GROUP_USER_TYPE.none,
      };
  }
};

const CONDITION_PERSONAL_QUERY = `'group'.id NOT IN (SELECT 'group'.id FROM 'group' WHERE 'group'.options like '%only_me%' AND 'group'.created = 'group'.seen_time)`;
