import { useEffect } from "react";
import { PubSubActions, SystemActions } from "redux-store";
import { StorageUtil } from "utils";
import { useDispatch } from "react-redux";

import HandlingUnreadMessage from "./HandlingUnreadMessage";
import HandlingBlockAccount from "./HandlingBlockAccount";
import HandlingOnlineStatus from "./HandlingOnlineStatus";
import HandlingBranchInvite from "./HandlingBranchInvite";

import HandleAppEvents from "./HandleAppEvents";
import HandlingMigrateVersion from "./HandlingMigrateVersion";

const HandlingParallel = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("HandlingParallel");
    dispatch(SystemActions.synchronizeData(StorageUtil.getCurrentPrefixKey()));
    dispatch(PubSubActions.initPubsub());

    return () => {
      // Ensure that all thead of logout branches are destroyed
      dispatch(PubSubActions.destroyLogoutBranchThread());
    };
  }, []);

  return (
    <>
      <HandlingBlockAccount />
      <HandlingUnreadMessage />
      <HandlingOnlineStatus />
      <HandlingBranchInvite />
      <HandleAppEvents />
      <HandlingMigrateVersion />
    </>
  );
};
export default HandlingParallel;
