import { AppConstant, KeyConstant, SystemConstant } from "const";
import store, { CallingActions, getReduxState } from "redux-store";
import { getCallHistory } from "sagas/calling.saga";
import { getInteractor } from "services/local.service";
import { StorageUtil, convertJSONObject, convertString2JSON, toCamel } from "utils";

export const handleCallingMessage = async (prefixKey, messageId, group) => {
  await getCallHistory({ prefixKey });
  const parentMessage = getReduxState(state => state.callingRedux.createdMessage);
  const oldOffers = getReduxState(state => state.callingRedux.offerMessage);
  const missingConnection = getReduxState(state => state.callingRedux.missingConnection);
  const acceptWithId = getReduxState(state => state.callingRedux.acceptWithId);
  let message = await getInteractor(prefixKey).LocalMessageService.get(messageId);
  message = toCamel(message);

  switch (message.callStatus) {
    case SystemConstant.MESSAGE_CALL_STATUS.waiting:
    case SystemConstant.MESSAGE_CALL_STATUS.accept:
      await handleAcceptOrWaitingCall(prefixKey, message, oldOffers, acceptWithId, missingConnection, group);
      break;

    case SystemConstant.MESSAGE_CALL_STATUS.end:
      await handleEndCall(prefixKey, message);
      break;

    case SystemConstant.MESSAGE_CALL_STATUS.reconnect:
      await handleReconnectCall(prefixKey, message, parentMessage, oldOffers);
      break;

    case SystemConstant.MESSAGE_CALL_STATUS.reject:
      await handleRejectCall(prefixKey, message);
      break;

    case SystemConstant.MESSAGE_CALL_STATUS.missed:
      await handleMissedCall(prefixKey, message);
      break;

    case SystemConstant.MESSAGE_CALL_STATUS.inAnotherCall:
      await handleInAnotherCall(prefixKey, message);
      break;

    default:
      break;
  }
};

const handleAcceptOrWaitingCall = async (prefixKey, message, oldOffers, acceptWithId, missingConnection, group) => {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID, prefixKey);
  const isGroupCall = ARR_CALLING_GROUP.includes(message.sendType);
  const isPersonalCall = ARR_CALLING_PERSONAL.includes(message.sendType);
  const messageContent = convertJSONObject(message.content);

  const isCurrentCalling = await checkCurrentCalling(prefixKey, message);
  console.log("handleAcceptOrWaitingCall", {
    message,
    group,
    isCurrentCalling,
  });
  if (!isCurrentCalling) return;

  if (message.parentId) {
    if (isGroupCall) {
      const isMsgFromAnotherDevice = message.senderId === accountId && message.senderDeviceId !== deviceId;
      // accept group call in another device
      if (message.callStatus === SystemConstant.MESSAGE_CALL_STATUS.accept && isMsgFromAnotherDevice) {
        store.dispatch(
          CallingActions.callingSet({
            isInAnotherCall: true,
          }),
        );
      }
      if (
        message.callStatus === SystemConstant.MESSAGE_CALL_STATUS.waiting &&
        !Boolean(messageContent.send_to_account_id)
      ) {
        if (isMsgFromAnotherDevice) {
          store.dispatch(CallingActions.onCallCheck({ accountId: accountId, isEndCall: true }, prefixKey));
        }

        const callHistory = toCamel(await getInteractor().LocalCallHistoryService.getLastByGroupId(group.id));
        if (callHistory && callHistory.status === SystemConstant.CALL_HISTORY_STATUS.end) {
          store.dispatch(
            CallingActions.callingSet({
              memberChange: {
                time: Date.now(),
                accountId: message.senderId,
              },

              isNotifyEnded: {
                groupId: group.id,
                roomId: convertString2JSON(message.content, {}).room_id,
              },
            }),
          );
        }
      } else if (messageContent.send_to_account_id === accountId) {
        if (Boolean(messageContent.type === SystemConstant.CALL_MSG_CONTENT_TYPE.offer)) {
          store.dispatch(
            CallingActions.callingSet({
              offerMes: message,
              offerMessage: {
                ...oldOffers,
                [message.senderId]: message,
              },
            }),
          );
        } else if (Boolean(messageContent.type === SystemConstant.CALL_MSG_CONTENT_TYPE.answer)) {
          store.dispatch(
            CallingActions.callingSet({
              acceptTimeStamp: [message.created],
              acceptWithId: {
                ...acceptWithId,
                [message.senderId]: {
                  ...message,
                },
              },
            }),
          );
        }
      } else if (
        messageContent.send_to_account_id !== accountId &&
        Boolean(messageContent.type === SystemConstant.CALL_MSG_CONTENT_TYPE.answer)
      ) {
        store.dispatch(
          CallingActions.callingSet({
            missingConnection: {
              ...missingConnection,
              [message.senderId]: {
                ...message,
              },
            },
          }),
        );
      }
    } else if (isPersonalCall) {
      // Personal call using jitsi: receiver accept call => set isCallAccepted = true (don't need to check peer connection)
      store.dispatch(
        CallingActions.callingSet({
          acceptMessage: toCamel(message),
          isCallAccepted: true, // remove if don't use jitsi
        }),
      );
    }
  } else {
    store.dispatch(
      CallingActions.callingSet({
        isVideoCall: ARR_VIDEO_CALLING.includes(message.sendType),
        isReceiver: true,
      }),
    );

    if (message.senderId !== accountId) {
      const isOpenCallingDialog = store.getState().callingRedux.isOpenCallingDialog;
      if (isOpenCallingDialog === AppConstant.CALLING_STATUS.notStart) {
        const createdMessage = store.getState().callingRedux.createdMessage;
        const isShowCallDialog =
          message.senderId !== accountId && Date.now() - message.created < AppConstant.CALL_WAITING_TIME;
        if (isShowCallDialog) {
          store.dispatch(
            CallingActions.callingSet({
              isOpenCallingDialog: AppConstant.CALLING_STATUS.notStart,
              createdMessage: {
                ...createdMessage,
                [message.groupId]: message,
              },
              callingGroupDetail: { ...group, prefixKey, roomId: messageContent.room_id },
              message: message,
              personalRequestMessage: message,
            }),
          );
        }
      }
    }
  }
};

const handleEndCall = async (prefixKey, message) => {
  // Jitsi 'participantLeft' event will listen and close calling if numberOfParticipant < 2 so sometime this logic unnecessary
  console.log("handleEndCall", { prefixKey, message });

  const count = await getInteractor(prefixKey).LocalMessageService.count({
    parent_id: message.parentId,
    call_status: SystemConstant.MESSAGE_CALL_STATUS.end,
  });
  // Skip if double ended event
  if (count > 1) return;

  const isCurrentCalling = await checkCurrentCalling(prefixKey, message);
  if (isCurrentCalling) store.dispatch(CallingActions.callingSet({ isCallEnded: message.groupId }));
};

// Message sendType = reconnect
const handleReconnectCall = async (prefixKey, message, parentMessage, oldOffers) => {
  console.log("reconnecting");

  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const messageContent = convertString2JSON(message.content);
  // parentMessage always be in camel format
  if (
    (messageContent.send_to_account_id === accountId &&
      parentMessage[message.groupId].sendType === SystemConstant.SEND_TYPE.groupVideoCall) ||
    parentMessage[message.groupId].sendType === SystemConstant.SEND_TYPE.groupCall ||
    parentMessage[message.groupId].sendType === SystemConstant.SEND_TYPE.conference
  ) {
    if (Boolean(messageContent.type === SystemConstant.CALL_MSG_CONTENT_TYPE.offer)) {
      store.dispatch(
        CallingActions.callingSet({
          offerMessage: {
            ...oldOffers,
            [message.senderId]: message,
          },
          offerMes: message,
          reconnectMessage: toCamel(message),
          memberChange: {
            time: Date.now(),
            accountId: message.senderId,
          },
        }),
      );
    }
  } else if (ARR_CALLING_PERSONAL.includes(parentMessage[message.groupId].sendType)) {
    store.dispatch(
      CallingActions.callingSet({
        reconnectMessage: toCamel(message),
        acceptMessage: messageContent.type === SystemConstant.CALL_MSG_CONTENT_TYPE.answer ? toCamel(message) : null,
        personalRequestMessage:
          messageContent.type === SystemConstant.CALL_MSG_CONTENT_TYPE.offer ? toCamel(message) : null,
      }),
    );
  }
};

const handleRejectCall = async (prefixKey, message) => {
  const isCurrentCalling = await checkCurrentCalling(prefixKey, message);
  console.log("handleRejectCall", { isCurrentCalling, message });

  // Reject status: Sent status when calling personal
  // With calling group, will sent waiting status
  if (isCurrentCalling) store.dispatch(CallingActions.callingSet({ isCallRejected: true }));
};

const handleMissedCall = async (prefixKey, message) => {
  const isCurrentCalling = await checkCurrentCalling(prefixKey, message);
  console.log("handleMissedCall", { isCurrentCalling, message });

  if (isCurrentCalling) {
    store.dispatch(CallingActions.callingSet({ isCallMissed: true }));
  }
};

const handleInAnotherCall = async (prefixKey, message) => {
  const isCurrentCalling = await checkCurrentCalling(prefixKey, message);
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  console.log("Picked at another device", {
    message,
    accountId,
    isCurrentCalling,
  });

  if (isCurrentCalling) {
    store.dispatch(
      CallingActions.callingSet({
        isInAnotherCall: true,
      }),
    );
  }
};

const ARR_CALLING_GROUP = [
  SystemConstant.SEND_TYPE.groupVideoCall,
  SystemConstant.SEND_TYPE.conference,
  SystemConstant.SEND_TYPE.groupCall,
];

const ARR_CALLING_PERSONAL = [SystemConstant.SEND_TYPE.personalCall, SystemConstant.SEND_TYPE.personalVideoCall];

const ARR_VIDEO_CALLING = [
  SystemConstant.SEND_TYPE.groupVideoCall,
  SystemConstant.SEND_TYPE.conference,
  SystemConstant.SEND_TYPE.personalVideoCall,
];

const checkCurrentCalling = async (prefixKey, message) => {
  const messageContent = convertJSONObject(message.content);
  const roomId = messageContent?.room_id || null;
  const callingGroupDetail = getReduxState(state => state.callingRedux.callingGroupDetail);

  const callHistory = callingGroupDetail?.roomId
    ? await getInteractor(prefixKey).LocalCallHistoryService.getLastItem({
        group_id: callingGroupDetail.id,
        room_id: callingGroupDetail.roomId,
      })
    : null;
  const isValid = !callHistory || (roomId && callingGroupDetail?.roomId === roomId);
  return isValid;
};
