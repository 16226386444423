import { createReducer, createActions } from "reduxsauce";
import {
  REDUX_STATE,
  requestReducerFunc,
  failureReducerFunc,
  successReducerFunc,
  setReducerFunc,
} from "./redux-structure";
import { AppConstant, KeyConstant, SystemConstant } from "const";
import { toCamel, toSnake } from "utils";
import { getInteractor } from "services/local.service";
const StorageUtil = window.electronUtils.storage;

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  uploadMessageFile: ["data", "prefixKey"],
  updateMessageStatus: ["data", "prefixKey"],
  conversationSetLinkData: ["data", "prefixKey"],
  sendMessage: ["data", "prefixKey"], // Send local message to server
  sendFileMessage: ["data", "prefixKey"],
  deleteMessageLocal: ["data", "prefixKey"], // Delete message of other account at local database
  receivedRemoteMessage: ["newMessage"], // Set new message from server
  getMessageDetail: ["sourceId", "prefixKey"], // Get/ save seen/ received member

  requestUploadImageCall: ["data", "prefixKey"],
  setSelectGroupId: ["data", "prefixKey"],
  checkBlockedContact: ["data", "prefixKey"], // data: {accountId: <Receiver's id>}
  markReadMessageInGroup: ["selectedGroupId", "threadingId"],
  clearNewMessage: ["categoryId", "deleteMessageIds"], // categoryId: group_id/ thread_id

  conversationSuccess: ["data"],
  conversationFailure: ["error", "data"],
  conversationSet: ["data"],
});

export const ConversationTypes = Types;
export const ConversationActions = Creators;
export default Creators;

/* ------------- Initial State ------------- */
export const DEFAULT_ATTACHMENT = {
  processStatus: AppConstant.PROCESS_STATUS.nothing,
  attachmentId: null,
  sendType: null,
};
export const INITIAL_STATE = {
  ...REDUX_STATE,

  isSearchMode: false,
  isUpdateViewMode: 0,
  fetchSeenMember: 0, // Timestamp value is update when fetching seen member successfully
  updateMessageStatus: { updateMessageIds: [], modified: 0 },
  updateThreadStatus: { threadUpdate: [], modified: 0 },

  threadingId: null,
  selectedGroupId: null,

  uploadingAttachment: DEFAULT_ATTACHMENT,

  idGroupCallAvatar: null, // Id của cuộc gọi conference trong trường hợp init
  idAvatarEdit: null, // Id của cuộc gọi conference trong trường hợp còn lại
  linkData: {}, // Preview link message
  isAddConference: false, // True: User đang trong luồng taojh conference

  scrollToChildId: null, // id của message mà mình muốn nhảy đến
  blockedAccount: null, // Dành riêng cho trường hợp chat cá nhân và thằng đó bị blocked
  searchingMessage: {
    selectedMessageId: null,
    messageList: [],
    searchValue: "",
  },

  incomingMessages: {}, // {[group_id]: {<message_1_id>: <message_1>}, [thread_id]: {<message_1_id>: <message_1>}}
};

/* ------------- Selector ------------- */
export const ConversationSelectors = {
  getSearchingMessage: state => state.conversationRedux.searchingMessage,
  getUploadingAttachment: state => state.conversationRedux.uploadingAttachment,
  getSelectedGroupId: state => state.conversationRedux.selectedGroupId,
  getThreadingId: state => state.conversationRedux.threadingId,
  getFetchSeenMember: state => state.conversationRedux.fetchSeenMember,
};

/* ------------- Reducers ------------- */
const request = (state = INITIAL_STATE) => requestReducerFunc({ ...state, fetchSeenMember: 0 });

const uploadMessageFile = (state = INITIAL_STATE) =>
  request({
    ...state,
    uploadingAttachment: { ...DEFAULT_ATTACHMENT, processStatus: AppConstant.PROCESS_STATUS.calling },
  });

// Delete message of other account
const deleteMessageLocal = (state = INITIAL_STATE, action) => {
  const { data, prefixKey } = action;
  const deleteMessage = {
    ...data,
    state: SystemConstant.STATE.inactive,
    sendType: SystemConstant.SEND_TYPE.deleteMessage,
    modified: Date.now(),
  };
  getInteractor(prefixKey).LocalMessageService.save([toSnake(deleteMessage)]);

  return {
    ...getStateWithNewMessage(toCamel(deleteMessage), state),
    isSearchMode: false,
  };
};

const setLinkData = (state = INITIAL_STATE, action) => ({ ...state, linkData: { ...state.linkData, ...action.data } });

const setSelectGroupId = (state = INITIAL_STATE, action) => {
  const { threadingId, scrollToChildId, ...otherData } = action.data || {};
  const isChangeGroup = state.selectedGroupId !== otherData.selectedGroupId;
  // Clear queue message in redux
  if (threadingId) {
    delete state.incomingMessages[threadingId];
    delete state.incomingMessages[state.threadingId];
  } else if (isChangeGroup) {
    delete state.incomingMessages[otherData.selectedGroupId];
    delete state.incomingMessages[state.selectedGroupId];
  }

  return {
    ...state,
    isSearchMode: isChangeGroup ? false : state.isSearchMode,
    scrollToChildId: scrollToChildId || null,
    threadingId: threadingId || null,
    ...otherData,
  };
};

// Received remote message from server
const receivedRemoteMessage = (state = INITIAL_STATE, action) => {
  const newMessage = action.newMessage;
  return getStateWithNewMessage(newMessage, state);
};

// Send local message to server
const sendMessage = (state = INITIAL_STATE, action) => {
  const { currentMessage } = action.data;
  return getStateWithNewMessage(currentMessage, state);
};

// Handle new message and return new state
const getStateWithNewMessage = (newMessage, state = INITIAL_STATE) => {
  newMessage = toCamel(newMessage);
  if (!(newMessage && newMessage.id)) return state;

  // If message has type that can be displayed on the list, update msg to state
  if (newMessage.groupId === state.selectedGroupId && SystemConstant.ARR_MSG_SEND_TYPES.includes(newMessage.sendType)) {
    if (!newMessage.senderId) newMessage.senderId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
    if (!newMessage.created) newMessage.created = Date.now();

    const incomingMessages = state.incomingMessages;
    const categoryId = newMessage.threadId || newMessage.groupId;
    const messageList = incomingMessages[categoryId] || {};

    return {
      ...state,
      incomingMessages: {
        ...incomingMessages,
        [categoryId]: { ...messageList, [newMessage.id]: newMessage },
      },
    };
  }

  return state;
};

const clearNewMessage = (state = INITIAL_STATE, action) => {
  const { categoryId, deleteMessageIds } = action;
  const incomingMessages = state.incomingMessages;
  const messageList = incomingMessages[categoryId];

  if (
    Array.isArray(messageList) &&
    messageList.length > 0 &&
    Array.isArray(deleteMessageIds) &&
    deleteMessageIds.length > 0
  ) {
    for (let index = 0; index < deleteMessageIds.length; index++) {
      const messageId = deleteMessageIds[index];
      delete incomingMessages[categoryId][messageId];
    }

    return {
      ...state,
      incomingMessages: {
        ...incomingMessages,
        [categoryId]: messageList,
      },
    };
  }

  return state;
};

const set = (state = INITIAL_STATE, action) => setReducerFunc(state, action);

const success = (state = INITIAL_STATE, action) => successReducerFunc(state, action);

const failure = (state = INITIAL_STATE, action) => failureReducerFunc(state, action);

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.UPLOAD_MESSAGE_FILE]: uploadMessageFile,
  [Types.UPDATE_MESSAGE_STATUS]: request,
  [Types.GET_MESSAGE_DETAIL]: request,

  [Types.SEND_MESSAGE]: sendMessage,
  [Types.SEND_FILE_MESSAGE]: sendMessage,
  [Types.DELETE_MESSAGE_LOCAL]: deleteMessageLocal,
  [Types.CONVERSATION_SET_LINK_DATA]: setLinkData,
  [Types.RECEIVED_REMOTE_MESSAGE]: receivedRemoteMessage,
  [Types.CLEAR_NEW_MESSAGE]: clearNewMessage,

  [Types.REQUEST_UPLOAD_IMAGE_CALL]: request,
  [Types.SET_SELECT_GROUP_ID]: setSelectGroupId,
  [Types.CHECK_BLOCKED_CONTACT]: request,
  [Types.MARK_READ_MESSAGE_IN_GROUP]: request,

  [Types.CONVERSATION_SET]: set,
  [Types.CONVERSATION_SUCCESS]: success,
  [Types.CONVERSATION_FAILURE]: failure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const ConversationReducer = createReducer(INITIAL_STATE, HANDLERS);
