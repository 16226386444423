/**
 * Implement logic to support the view layer
 */

import { groupByByKey, isExternalLink, removeVietnameseTones } from "utils";
import { KeyConstant, SystemConstant } from "const";
import { deepCloneJsonObject, removeDuplicateInArray, toCamel } from "./index";
import { getInteractor } from "services/local.service";
import store from "redux-store";

const StorageUtil = window.electronUtils.storage;

export const showConfirmLeaveGroup = async (data, setIsConfirmLeave, setIsChooseAdmin) => {
  const memberArray = data.groupMembers;
  const adminList = toCamel(await getInteractor().LocalAccountGroupService.find({ group_id: data.id }))
    .filter(item => item.type === SystemConstant.ROLE_OBJ.admin)
    .map(item => memberArray.find(member => member.id === item.accountId));

  const currentAccountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  if (adminList.find(element => element.id === currentAccountId)) {
    setIsChooseAdmin(true);
  } else {
    setIsConfirmLeave(true);
  }
};

const NORMAL_MESSAGE_SEND_TYPE = [SystemConstant.SEND_TYPE.message, SystemConstant.SEND_TYPE.groupMessage];
const NOTICE_CALL_STATUS = [SystemConstant.MESSAGE_CALL_STATUS.missed, SystemConstant.MESSAGE_CALL_STATUS.end];

export const notificationClear = async result => {
  const prefixKey = StorageUtil.getCurrentPrefixKey();
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
  const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);
  const mapByGroup = {};
  let clearList = [];
  let allNotSeen = {};
  result.forEach(obj => {
    if (!mapByGroup[obj.group_id]) {
      mapByGroup[obj.group_id] = [];
    }
    mapByGroup[obj.group_id].push(obj);
    if (!allNotSeen[obj.id]) {
      allNotSeen[obj.id] = obj;
    }
  });
  let groupDeleted =
    Object.keys(mapByGroup).length === 0
      ? []
      : await getInteractor().LocalGroupService.find({
          account_id: accountId,
          branch_id: branchId,
          id: Object.keys(mapByGroup),
        });
  let groupIds = groupDeleted.map(gd => gd.id);
  let groupDeletedIds = groupDeleted
    .filter(
      gd => gd.state === SystemConstant.STATE.inactive || gd.account_group_state === SystemConstant.STATE.inactive,
    )
    .map(gd => gd.id);
  let diff = Object.keys(mapByGroup);
  diff = diff.filter(d => !groupIds.includes(d));
  diff = diff.concat(groupDeletedIds);
  diff.forEach(it => {
    mapByGroup[it].forEach(mes => {
      if (mes) {
        mes.status = SystemConstant.MESSAGE_STATUS.read;
        delete allNotSeen[mes.id];
        clearList.push(mes);
      }
    });
  });

  let threadMap = groupByByKey("thread_id", Object.values(allNotSeen));
  let threadParentMessageIds = (
    await getInteractor().LocalMessageService.find({ source_id: Object.keys(threadMap) })
  ).map(it => it.source_id);
  threadParentMessageIds.forEach(tp => {
    delete threadMap[tp];
  });
  Object.keys(threadMap).forEach(tm => {
    if (threadMap[tm]) {
      threadMap[tm].forEach(mes => {
        mes.status = SystemConstant.MESSAGE_STATUS.read;
        delete allNotSeen[mes.id];
        clearList.push(mes);
      });
    }
  });
  let parentMap = groupByByKey("parent_id", Object.values(allNotSeen));
  let parentMessage = (
    await getInteractor().LocalMessageService.find({
      source_id: Object.keys(parentMap),
    })
  ).map(it => it.source_id);
  parentMessage.forEach(tp => {
    delete parentMap[tp];
  });
  Object.keys(parentMap).forEach(tm => {
    if (parentMap[tm]) {
      parentMap[tm].forEach(mes => {
        mes.status = SystemConstant.MESSAGE_STATUS.read;
        delete allNotSeen[mes.id];
        clearList.push(mes);
      });
    }
  });
  let clone = deepCloneJsonObject(Object.values(allNotSeen));
  clone.forEach(al => {
    if (
      al.send_type === SystemConstant.SEND_TYPE.deleteMessage ||
      al.send_type === SystemConstant.SEND_TYPE.deleteReaction ||
      (NORMAL_MESSAGE_SEND_TYPE.includes(al.send_type) && Boolean(al.parent_id)) || // edited message
      (SystemConstant.ARR_CALLING_TYPES.includes(al.send_type) && !NOTICE_CALL_STATUS.includes(al.call_status)) // notice call
    ) {
      delete allNotSeen[al.id];
      clearList.push(al);
    }
  });
  let cloneList = deepCloneJsonObject(Object.values(allNotSeen));
  for (let index = 0; index < cloneList.length; index++) {
    const notification = cloneList[index];
    try {
      const seenMemberIds = (
        await getInteractor().LocalSeenMemberService.find({ source_id: notification.source_id })
      ).map(item => item.member_account_id);

      if (Array.isArray(seenMemberIds) && seenMemberIds.includes(accountId)) {
        delete allNotSeen[notification.id];
        clearList.push(notification);
      }
    } catch (e) {
      console.log(e);
    }
  }

  clearList = clearList.filter(c => c.status !== SystemConstant.NOTIFICATION_STATUS.read);
  return { allNotSeen: allNotSeen, clearList: clearList };
};

// Return new mention arr in thread
export const validateMentions = (threadInfo, newMentions, parentMentions) => {
  const threadMentions = Boolean(threadInfo) ? JSON.parse(threadInfo.thread_mentions) : [];

  let newMentionArr = [];
  if (newMentions && Array.isArray(newMentions)) {
    newMentionArr = newMentions;
  } else if (newMentions) {
    newMentionArr = JSON.parse(newMentions);
  }
  const parentMentionArr = Boolean(parentMentions) ? JSON.parse(parentMentions) : [];
  return removeDuplicateInArray([...parentMentionArr, ...threadMentions, ...newMentionArr]);
};

export const validateInvolved = async (message, mentionArr) => {
  const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
  const parentMessage =
    (await getInteractor().LocalMessageService.findOne({
      source_id: message.thread_id,
    })) || {};

  return parentMessage.sender_id === accountId || message.sender_id === accountId || mentionArr.includes(accountId);
};

export const getSearchResult = (searchValue, inputData = [], searchFields) => {
  if (!searchValue || searchValue === "" || false === Array.isArray(inputData)) return inputData;

  const filteredList = inputData.filter(item => {
    const field2RemoveVietnameseTones = searchFields.map(field => item[field] || "");
    return removeVietnameseTones(`${field2RemoveVietnameseTones.join(", ")}`)
      .toLowerCase()
      .includes(removeVietnameseTones(searchValue.toLowerCase()));
  });

  return filteredList;
};

// Handling send_type if it is missing
export const getSendType = (sendType, content, isPersonal) => {
  let newSendType = sendType;

  if (false === Object.values(SystemConstant.SEND_TYPE).includes(sendType)) {
    // Set the default send_type based on the current user is chatting in a group or with an individual
    newSendType = isPersonal ? SystemConstant.SEND_TYPE.message : SystemConstant.SEND_TYPE.groupMessage;
  }

  // Update sendType if message is text and contain url
  if (
    [SystemConstant.SEND_TYPE.message, SystemConstant.SEND_TYPE.groupMessage].includes(newSendType) &&
    isExternalLink(content)
  ) {
    newSendType = SystemConstant.SEND_TYPE.link;
  }

  return newSendType;
};

export const isChangeGroupData = (group, compareGroup) => {
  const isChangeGroup = group.id && group.id === compareGroup.id;
  const isChangeGroupName = compareGroup.groupName && group.groupName !== compareGroup.groupName;
  const isChangeGroupAvar = compareGroup.avatarId && group.avatarId !== compareGroup.avatarId;
  const isChangeGroupMember = compareGroup.groupMembers && group.groupMembers !== compareGroup.groupMembers;
  return isChangeGroup || isChangeGroupName || isChangeGroupAvar || isChangeGroupMember;
};

export const isGroupOrChannelType = groupType => {
  return Boolean(
    groupType &&
      (groupType === SystemConstant.GROUP_CHAT_TYPE.group || groupType === SystemConstant.GROUP_CHAT_TYPE.channel),
  );
};

export const checkCurrentGroup = groupId => groupId === store.getState().conversationRedux.selectedGroupId;
