import { KeyConstant } from "const";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notificationClear } from "utils/view.utils";
import { StorageUtil, convertString2JSON } from "utils";
import { getInteractor } from "services/local.service";
import { BranchSelectors, ConversationSelectors, GroupInfoActions } from "redux-store";
import { createSelector } from "reselect";
import useLazyEffect from "./useLazyEffect";
import useCleanUpEffect from "./useCleanUpEffect";

const memoizedReduxState = createSelector(
  [
    BranchSelectors.getSelectedBranch,
    state => state.conversationRedux.isUpdateViewMode,
    ConversationSelectors.getSelectedGroupId,
  ],
  (selectedBranch, isUpdateViewMode, selectedGroupId) => {
    return {
      selectedBranchId: selectedBranch.id,
      isUpdateViewMode,
      selectedGroupId,
    };
  },
);

const useUnreadMessage = groupId => {
  const dispatch = useDispatch();
  const { selectedBranchId, isUpdateViewMode, selectedGroupId } = useSelector(memoizedReduxState);
  const { isMounted } = useCleanUpEffect();

  const [allUnreadMessages, setAllUnreadMessages] = useState(0);
  const [unreadInGroupNumber, setUnreadInGroupNumber] = useState(0);
  const [unreadNotification, setUnreadNotification] = useState(0);

  const updateTotalUnread = useCallback(async () => {
    const prefixKey = StorageUtil.getCurrentPrefixKey();
    const numberUnreadNotice = (
      await getInteractor(prefixKey).LocalNotificationService.getUnreadNormalNoticeInBranch(selectedBranchId)
    ).length;
    setUnreadNotification(numberUnreadNotice);
  }, [selectedBranchId]);

  const fetchUnreadMessage = useCallback(async (selectedBranchId, groupId, selectedGroupId, currentUnreadInGroup) => {
    const prefixKey = StorageUtil.getCurrentPrefixKey();
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
    const branchId = StorageUtil.getItem(KeyConstant.KEY_BRANCH_ID, prefixKey);

    const numberUnreadNotice = (
      await getInteractor(prefixKey).LocalNotificationService.getUnreadNormalNoticeInBranch(selectedBranchId)
    ).length;
    setUnreadNotification(numberUnreadNotice);

    const unreadMsg = await getInteractor(prefixKey).LocalMessageService.getUnreadMessageInGroup({
      group_id: groupId,
      account_id: accountId,
      branch_id: branchId,
    });

    const allUnreadMessage = await notificationClear(unreadMsg);
    const allMsgNotSeen = allUnreadMessage.allNotSeen;

    let tmpUnreadMsg = [];

    for (const msg of Object.values(allMsgNotSeen).filter(item => item.branch_id === selectedBranchId)) {
      const currentGroup = await getInteractor(prefixKey).LocalGroupService.findById(msg.group_id);
      const options = convertString2JSON(currentGroup.options, {});

      if (!(options.hidden && options.hidden.includes(accountId))) {
        tmpUnreadMsg = [...tmpUnreadMsg, msg];
      }
    }

    if (groupId) {
      const unreadMsgInGroupLength = tmpUnreadMsg.filter(item => item.group_id === groupId).length;
      if (currentUnreadInGroup !== unreadMsgInGroupLength) {
        if (isMounted()) setUnreadInGroupNumber(unreadMsgInGroupLength);
        getInteractor(prefixKey).LocalGroupService.update(
          {
            unread: unreadMsgInGroupLength,
            modified: Date.now(),
          },
          {
            id: groupId,
          },
        );
        if (false === Boolean(selectedGroupId === groupId && currentUnreadInGroup === 0)) {
          dispatch(
            GroupInfoActions.groupInfoSuccess({
              updateGroupCategory: {
                id: groupId,
                unread: unreadMsgInGroupLength,
                modified: Date.now(),
              },
            }),
          );
        }
      }
    } else setAllUnreadMessages(tmpUnreadMsg.length);
  }, []);

  useLazyEffect(() => {
    fetchUnreadMessage(selectedBranchId, groupId, selectedGroupId, unreadInGroupNumber);
  }, [isUpdateViewMode, selectedBranchId, groupId, selectedGroupId, unreadInGroupNumber]);

  return {
    allUnreadMessages,
    unreadInGroupNumber,
    unreadNotification,
    totalUnread: allUnreadMessages + unreadNotification,
    updateTotalUnread,
  };
};

export default useUnreadMessage;
