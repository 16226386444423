// List language code
const DEFAULT_LANG = "vi";

// Namespace Key
const NS_COMMON = "common";
const NS_LOGIN = "login";
const NS_NOT_FOUND = "notFound";
const NS_HOME = "home";
const NS_HOME_CONVERSATION = "homeConversation";
const NS_CALLING = "callingLang";
const NS_MANAGE_FILE = "file";

// Common start
const TXT_APP_NAME = "TXT_APP_NAME";
const TXT_PHONE_NUMBER = "TXT_PHONE_NUMBER";
const TXT_CONTINUE = "TXT_CONTINUE";
const TXT_ACTIVE = "TXT_ACTIVE";
const TXT_INACTIVE = "TXT_INACTIVE";
const TXT_OTHER = "TXT_OTHER";
const TXT_EATING = "TXT_EATING";
const TXT_BEAUTY = "TXT_BEAUTY";
const TXT_SPORT = "TXT_SPORT";
const TXT_HEALTH = "TXT_HEALTH";
const TXT_PET = "TXT_PET";
const TXT_REPAIR = "TXT_REPAIR";
const TXT_OK = "TXT_OK";
const TXT_RETRY = "TXT_RETRY";
const TXT_CONTACT_PHONE = "TXT_CONTACT_PHONE";
const TXT_CONTACT_EMAIL = "TXT_CONTACT_EMAIL";
const TXT_COMPANY_NAME = "TXT_COMPANY_NAME";
const TXT_DELETE = "TXT_DELETE";
const TXT_REPLY = "TXT_REPLY";
const TXT_SEARCH = "TXT_SEARCH";
const TXT_ACCEPT = "TXT_ACCEPT";
const TXT_DENIED = "TXT_DENIED";
const TXT_CANCEL = "TXT_CANCEL";
const TXT_CONFIRM = "TXT_CONFIRM";
const TXT_POPUP_DOCUMENT = "TXT_POPUP_DOCUMENT";
const TXT_POPUP_CAMERA = "TXT_POPUP_CAMERA";
const TXT_POPUP_GALLERY = "TXT_POPUP_GALLERY";
const TXT_POPUP_AUDIO = "TXT_POPUP_AUDIO";
const TXT_PLACEHOLDER = "TXT_PLACEHOLDER";
const TXT_DEFAULT_USER_NAME = "TXT_DEFAULT_USER_NAME";
const TXT_HOUR = "TXT_HOUR";
const TXT_MINUTE = "TXT_MINUTE";
const TXT_SECOND = "TXT_SECOND";
const TXT_MONTH = "TXT_MONTH";
const TXT_DAY = "TXT_DAY";
const TXT_YEAR = "TXT_YEAR";
const TXT_COPY = "TXT_COPY";
const TXT_EDIT = "TXT_EDIT";
const TXT_CLOSE = "TXT_CLOSE";
const TXT_CUSTOMIZE = "TXT_CUSTOMIZE";
const TXT_RESET = "TXT_RESET";
const TXT_SAVE = "TXT_SAVE";
const TXT_SESSION_EXPIRED = "TXT_SESSION_EXPIRED";
const TXT_DOWNLOAD = "TXT_DOWNLOAD";
const TXT_YES = "TXT_YES";
const TXT_NO = "TXT_NO";
const TXT_CALL_ACCEPT = "TXT_CALL_ACCEPT";
const TXT_NO_INTERNET = "TXT_NO_INTERNET";
const TXT_HIDE = "TXT_HIDE";
const TXT_SERVER_ERROR = "TXT_SERVER_ERROR";
const TXT_PASSCODE_TITLE = "TXT_PASSCODE_TITLE";
const TXT_PASSCODE_SUBTITLE = "TXT_PASSCODE_SUBTITLE";
const TXT_RESTORE_PASSCODE_SUBTITLE = "TXT_RESTORE_PASSCODE_SUBTITLE";
const TXT_RESTORE_CONFIRM = "TXT_RESTORE_CONFIRM";
const TXT_BACKUP = "TXT_BACKUP";
const TXT_RESTORE = "TXT_RESTORE";
const TXT_BACKUP_INSTRUCTION = "TXT_BACKUP_INSTRUCTION";
const TXT_BACKUP_WARNING = "TXT_BACKUP_WARNING";
const TXT_PASSCODE_INSTRUCTION = "TXT_PASSCODE_INSTRUCTION";
const TXT_CREATE_PASSCODE = "TXT_CREATE_PASSCODE";
const TXT_BACKUP_SUCCESS = "TXT_BACKUP_SUCCESS";
const TXT_NO_BACKUP = "TXT_NO_BACKUP";
const TXT_RESTORE_ACTION = "TXT_RESTORE_ACTION";
const TXT_RESTORE_INSTRUCTION = "TXT_RESTORE_INSTRUCTION";
const TXT_BLOCKED_RESTORE = "TXT_BLOCKED_RESTORE";
const TXT_BLOCKED_RESTORE_CONTENT = "TXT_BLOCKED_RESTORE_CONTENT";
const TXT_GOT_IT = "TXT_GOT_IT";
const TXT_KEEP_CONNECT_INTERNET = "TXT_KEEP_CONNECT_INTERNET";
const TXT_NOT_CONNECT = "TXT_NOT_CONNECT";
const TXT_NOT_CONNECT_INFO = "TXT_NOT_CONNECT_INFO";
const TXT_RESTORE_SUCCESS = "TXT_RESTORE_SUCCESS";
const TXT_CONFIRM_LOGOUT_MASTER = "TXT_CONFIRM_LOGOUT_MASTER";
const TXT_CONTENT_LOGOUT_MASTER = "TXT_CONTENT_LOGOUT_MASTER";
const TXT_CHOOSE_DEVICE = "TXT_CHOOSE_DEVICE";
const TXT_CHOOSE_DEVICE_CONFIRM = "TXT_CHOOSE_DEVICE_CONFIRM";
const TXT_CHOOSE_DEVICE_SUCCESS = "TXT_CHOOSE_DEVICE_SUCCESS";
const TXT_LOGOUT_LATER = "TXT_LOGOUT_LATER";
const TXT_CONTENT_LOGOUT_SLAVE = "TXT_CONTENT_LOGOUT_SLAVE";
const TXT_LOSE_MASTER_PERMISSION = "TXT_LOSE_MASTER_PERMISSION";
const TXT_BLOCK_CHANGE_DEVICE_ROLE = "TXT_BLOCK_CHANGE_DEVICE_ROLE";
const TXT_LOGOUT_MASTER_WITHOUT_SLAVE = "TXT_LOGOUT_MASTER_WITHOUT_SLAVE";
const TXT_CHECKING_RESTORE_DATA = "TXT_CHECKING_RESTORE_DATA";

const FM_PASSCODE_BLOCKED = "FM_PASSCODE_BLOCKED";
const FM_RESTORE_SUBTITLE = "FM_RESTORE_SUBTITLE";
const FM_PASSCODE_WRONG = "FM_PASSCODE_WRONG";

const OBJ_ACC_STATUS = "OBJ_ACC_STATUS";
const OBJ_SEND_MES_TYPE = "OBJ_SEND_MES_TYPE";
const OBJ_BACKUP_INFO = "OBJ_BACKUP_INFO";

const ARR_DAY_OF_WEEK = "ARR_DAY_OF_WEEK";

// Common end

// Login start
const TXT_LOGIN = "TXT_LOGIN";
const TXT_LOGOUT = "TXT_LOGOUT";
const TXT_RESTORE_DATA = "TXT_RESTORE_DATA";
const TXT_RESEND_OTP = "TXT_RESEND_OTP";
const TXT_RESEND_SMS_OTP = "TXT_RESEND_SMS_OTP";
const TXT_SENT_OTP_TITLE = "TXT_SENT_OTP_TITLE";
const TXT_NAME_TITLE = "TXT_NAME_TITLE";
const TXT_SENT_SMS_OTP_TITLE = "TXT_SENT_SMS_OTP_TITLE";
const TXT_SENT_SMART_OTP_TITLE = "TXT_SENT_SMART_OTP_TITLE";
const TXT_REMEMBER_ME = "TXT_REMEMBER_ME";
const TXT_OTP_ERROR_MESSAGE = "TXT_OTP_ERROR_MESSAGE";
const TXT_OTP_LOCKED = "TXT_OTP_LOCKED";
const TXT_OTP_LOCKED_FULL_MESSAGE = "TXT_OTP_LOCKED_FULL_MESSAGE";
const TXT_UNREGISTERED_MESSAGE = "TXT_UNREGISTERED_MESSAGE";
const TXT_CHANGE_PHONE_NUMBER = "TXT_CHANGE_PHONE_NUMBER";
const TXT_CHANGE_SMS_OTP = "TXT_CHANGE_SMS_OTP";
const TXT_CHANGE_SMART_OTP = "TXT_CHANGE_SMART_OTP";
const TXT_TRY_AGAIN_LATTER = "TXT_TRY_AGAIN_LATTER";
const TXT_RESTORE_DATA_WARNING = "TXT_RESTORE_DATA_WARNING";
const TXT_RESTORE_DATA_INPROGRESS = "TXT_RESTORE_DATA_INPROGRESS";
const TXT_RESTORE_DATA_ERROR = "TXT_RESTORE_DATA_ERROR";
const TXT_SENT_OTP_MESSAGE = "TXT_SENT_OTP_MESSAGE";
const TXT_SENT_SMS_OTP_MESSAGE = "TXT_SENT_SMS_OTP_MESSAGE";
const TXT_SENT_SMART_OTP_MESSAGE = "TXT_SENT_SMART_OTP_MESSAGE";
const TXT_OTP_EXPIRED = "TXT_OTP_EXPIRED";
const TXT_LIMIT_DEVICE = "TXT_LIMIT_DEVICE";
const TXT_LOGIN_WELCOME_TITLE = "TXT_LOGIN_WELCOME_TITLE";
const TXT_LOGIN_WELCOME_SUBTITLE = "TXT_LOGIN_WELCOME_SUBTITLE";

const FM_COUNTDOWN_OTP = "FM_COUNTDOWN_OTP";
const FM_PHONE_CODE = "FM_PHONE_CODE";

// Login end

// NotFound start
const TXT_PAGE_NOT_FOUND = "TXT_PAGE_NOT_FOUND";
const TXT_PAGE_NOT_FOUND_DESCRIPTION = "TXT_PAGE_NOT_FOUND_DESCRIPTION";
// NotFound end

// Home start
const TXT_ACCOUNT = "TXT_ACCOUNT";
const TXT_SERVERS = "TXT_SERVERS";
const TXT_BRAND_SERVERS = "TXT_BRAND_SERVERS";
const TXT_CHATS = "TXT_CHATS";
const TXT_CONTACT = "TXT_CONTACT";
const TXT_NOTIFICATION = "TXT_NOTIFICATION";
const TXT_NO_MESSAGE = "TXT_NO_MESSAGE";
const TXT_ALL = "TXT_ALL";
const TXT_CHANNEL = "TXT_CHANNEL";
const TXT_PERSONAL = "TXT_PERSONAL";
const TXT_GROUP = "TXT_GROUP";
const TXT_CHAT_SEARCH_PLACE_HOLDER = "TXT_CHAT_SEARCH_PLACE_HOLDER";
const TXT_NO_NOTIFICATION = "TXT_NO_NOTIFICATION";
const TXT_CONFIRM_REMOVE_PHOTO = "TXT_CONFIRM_REMOVE_PHOTO";
const TXT_SURE_REMOVE = "TXT_SURE_REMOVE";
const TXT_KEYWORDS = "TXT_KEYWORDS";
const TXT_CLEAR_ALL = "TXT_CLEAR_ALL";
const TXT_NOT_FOUND = "TXT_NOT_FOUND";
const TXT_NO_SEARCH_KEYWORDS = "TXT_NO_SEARCH_KEYWORDS";
const TXT_CONTACT_FOUND = "TXT_CONTACT_FOUND";
const TXT_GROUP_FOUND = "TXT_GROUP_FOUND";
const TXT_CHANNEL_FOUND = "TXT_CHANNEL_FOUND";
const TXT_MESSAGE_FOUND = "TXT_MESSAGE_FOUND";
const TXT_ABOUT_US = "TXT_ABOUT_US";
const TXT_INCOMING_CALL = "TXT_INCOMING_CALL";
const TXT_INCOMING_VIDEO_CALL = "TXT_INCOMING_VIDEO_CALL";
const TXT_INCOMING_CALL_DESC = "TXT_INCOMING_CALL_DESC";
const TXT_GROUP_ITEM_OUTGOING_CALL = "TXT_GROUP_ITEM_OUTGOING_CALL";
const TXT_GROUP_ITEM_INCOMING_CALL = "TXT_GROUP_ITEM_INCOMING_CALL";
const TXT_GROUP_CALLING = "TXT_GROUP_CALLING";
const TXT_NO_MEMBER_CHOOSE = "TXT_NO_MEMBER_CHOOSE";
const TXT_CHANGE_ROLE = "TXT_CHANGE_ROLE";
const TXT_CONFIRM_CHANGE_ROLE_TITLE = "TXT_CONFIRM_CHANGE_ROLE_TITLE";
const TXT_CONFIRM_CHANGE_ROLE_CONTENT = "TXT_CONFIRM_CHANGE_ROLE_CONTENT";
const TXT_CHANGE_ROLE_OTP_TITLE = "TXT_CHANGE_ROLE_OTP_TITLE";
const TXT_VERIFY = "TXT_VERIFY";
const TXT_VIEW_MESSAGE_THREAD = "TXT_VIEW_MESSAGE_THREAD";
const TXT_VIEW_MORE = "TXT_VIEW_MORE";
const TXT_MEMBER_OVER_TOOLTIP = "TXT_MEMBER_OVER_TOOLTIP";
const TXT_FLOW_MESSAGE = "TXT_FLOW_MESSAGE";
const TXT_NO_UNSEEN_THREAD = "TXT_NO_UNSEEN_THREAD";
const TXT_REPLY_THREAD_MESSAGE = "TXT_REPLY_THREAD_MESSAGE";

const FM_WELCOME = "FM_WELCOME";
const FM_MENTION_NOTIFICATION = "FM_MENTION_NOTIFICATION";
const FM_MENTION_INBOX = "FM_MENTION_INBOX";
const FM_OTP_NOTIFICATION = "FM_OTP_NOTIFICATION";
const FM_OWNER_NOTIFICATION = "FM_OWNER_NOTIFICATION";
const FM_INCOMING_CALL_TITLE = "FM_INCOMING_CALL_TITLE";
const FM_INCOMING_VIDEO_CALL_TITLE = "FM_INCOMING_VIDEO_CALL_TITLE";
const FM_INCOMING_GROUP_CALL_TITLE = "FM_INCOMING_GROUP_CALL_TITLE";
const FM_INCOMING_GROUP_VIDEO_CALL_TITLE = "FM_INCOMING_GROUP_VIDEO_CALL_TITLE";
const FM_OTHER_CALL_PARTICIPANT = "FM_OTHER_CALL_PARTICIPANT";
const FM_DOWNLOADED_CONTENT = "FM_DOWNLOADED_CONTENT";
const FM_DOWNLOAD_SPEED = "FM_DOWNLOAD_SPEED";
const FM_CHANGE_ROLE_OTP_CONTENT = "FM_CHANGE_ROLE_OTP_CONTENT";
const FM_NUMBER_THREAD_REPLY = "FM_NUMBER_THREAD_REPLY";
const FM_MEMBER = "FM_MEMBER";
const FM_NUMBER_FLOW_MESSAGE = "FM_NUMBER_FLOW_MESSAGE";
const UNSEEN_MESSAGE = "UNSEEN_MESSAGE";
const FM_CHOOSE_MEMBER = "FM_CHOOSE_MEMBER";

const OBJ_ACCOUNT_ME = "OBJ_ACCOUNT_ME";
const OBJ_ACCOUNT_PROFILE = "OBJ_ACCOUNT_PROFILE";
const OBJ_EDIT_PROFILE = "OBJ_EDIT_PROFILE";
const OBJ_ACCOUNT_LICENSE = "OBJ_ACCOUNT_LICENSE";
const OBJ_ACCOUNT_ABOUT = "OBJ_ACCOUNT_ABOUT";
const OBJ_ACCOUNT_DEVICE = "OBJ_ACCOUNT_DEVICE";
const OBJ_ADDING_CHAT = "OBJ_ADDING_CHAT";
const OBJ_ADDING_CONTACT = "OBJ_ADDING_CONTACT";
const OBJ_DEVICE_TYPE_TEXT = "OBJ_DEVICE_TYPE_TEXT";
// Home end

// Conversation start
const TXT_PASTE = "TXT_PASTE";
const TXT_CHANGE_ALIAS = "TXT_CHANGE_ALIAS";
const TXT_VIEW = "TXT_VIEW";
const TXT_PEOPLE = "TXT_PEOPLE";
const TXT_ADD_NEW_MEMBER = "TXT_ADD_NEW_MEMBER";
const TXT_CHOOSE_NEW_ADMIN = "TXT_CHOOSE_NEW_ADMIN";
const TXT_MY_CONTACT = "TXT_MY_CONTACT";
const TXT_DELETE_FOR_YOU = "TXT_DELETE_FOR_YOU";
const TXT_VIEW_POPUP = "TXT_VIEW_POPUP";
const TXT_DELETE_POPUP = "TXT_DELETE_POPUP";
const TXT_STATIC_REACTION = "TXT_STATIC_REACTION";
const TXT_CUSTOM_EMOJI = "TXT_CUSTOM_EMOJI";
const TXT_REACTIONS = "TXT_REACTIONS";
const TXT_THREAD = "TXT_THREAD";
const TXT_PUBLIC_CHANNEL = "TXT_PUBLIC_CHANNEL";
const TXT_FILE_NOT_FOUND = "TXT_FILE_NOT_FOUND";
const TXT_EDITTED = "TXT_EDITTED";
const TXT_DELETE_CONTACT = "TXT_DELETE_CONTACT";
const TXT_CONTENT_DELETE_CONTACT = "TXT_CONTENT_DELETE_CONTACT";
const TXT_SEND_FILE_CONFIRM_TEXT = "TXT_SEND_FILE_CONFIRM_TEXT";
const TXT_UPLOAD_FAILED = "TXT_UPLOAD_FAILED";
const TXT_CONFIRM_SENDING_MEDIA = "TXT_CONFIRM_SENDING_MEDIA";
const TXT_INPUT_CAPTION = "TXT_INPUT_CAPTION";
const TXT_INVITE_TO_SERVER = "TXT_INVITE_TO_SERVER";
const TXT_SENDING = "TXT_SENDING";
const TXT_SENT = "TXT_SENT";
const TXT_RECEIVED = "TXT_RECEIVED";
const TXT_SEND_FAILED = "TXT_SEND_FAILED";
const TXT_INVITE_REJECTED = "TXT_INVITE_REJECTED";
const TXT_INVITE_DISMISSED = "TXT_INVITE_DISMISSED";
const TXT_INVITE_ACCEPTED = "TXT_INVITE_ACCEPTED";
const TXT_SEEN = "TXT_SEEN";
const TXT_CONFERENCE_CALL = "TXT_CONFERENCE_CALL";
const TXT_INACTIVE_USER = "TXT_INACTIVE_USER";

const FM_LAST_MESSAGE_MINE = "FM_LAST_MESSAGE_MINE";
const FM_CONFIRM_DETAIL = "FM_CONFIRM_DETAIL";
const FM_CONFIRM_REMOVE = "FM_CONFIRM_REMOVE";
const FM_FILE_SIZE_MEGA_BYTES = "FM_FILE_SIZE_MEGA_BYTES";
const FM_INVITE_TO_SERVER = "FM_INVITE_TO_SERVER";
const FM_SINGULAR_RESULT = "FM_SINGULAR_RESULT";
const FM_MANY_RESULTS = "FM_MANY_RESULTS";
const FM_REPLY_COUNT = "FM_REPLY_COUNT";
const FM_NEW_REPLY_COUNT = "FM_NEW_REPLY_COUNT";
const CONFIRM_DELETE_MESSAGE = "CONFIRM_DELETE_MESSAGE";
const CONFIRM_DELETE_MESSAGE_CONTENT = "CONFIRM_DELETE_MESSAGE_CONTENT";
const FM_SEEN_MEMBER = "FM_SEEN_MEMBER";
const FM_CONFIRM_REMOVE_CHANNEL_MEMBER = "FM_CONFIRM_REMOVE_CHANNEL_MEMBER";

// Conversation end

// GroupInfo start
const TXT_VOICE_CALL = "TXT_VOICE_CALL";
const TXT_VIDEO_CALL = "TXT_VIDEO_CALL";
const TXT_ADD = "TXT_ADD";
const TXT_MUTE = "TXT_MUTE";
const TXT_UNMUTE = "TXT_UNMUTE";
const TXT_SEE_GROUP_MEMBERS = "TXT_SEE_GROUP_MEMBERS";
const TXT_MEMBERS = "TXT_MEMBERS";
const TXT_CHANGE_NAME_GROUP = "TXT_CHANGE_NAME_GROUP";
const TXT_GROUP_INFO = "TXT_GROUP_INFO";
const TXT_GALLERY = "TXT_GALLERY";
const TXT_SEARCH_IN_CONVERSATION = "TXT_SEARCH_IN_CONVERSATION";
const TXT_CHANGE_GROUP_PHOTO = "TXT_CHANGE_GROUP_PHOTO";
const TXT_END_TO_END_ENCRYPTION = "TXT_END_TO_END_ENCRYPTION";
const TXT_PRIVACY = "TXT_PRIVACY";
const TXT_PERSONAL_PRIVACY = "TXT_PERSONAL_PRIVACY";
const TXT_NO_MEDIA = "TXT_NO_MEDIA";
const TXT_CONTENT_NO_MEDIA = "TXT_CONTENT_NO_MEDIA";
const TXT_NO_FILE = "TXT_NO_FILE";
const TXT_CONTENT_NO_FILE = "TXT_CONTENT_NO_FILE";
const TXT_NO_LINK = "TXT_NO_LINK";
const TXT_CONTENT_NO_LINK = "TXT_CONTENT_NO_LINK";
const TXT_MEDIA = "TXT_MEDIA";
const TXT_LINK = "TXT_LINK";
const TXT_FILES = "TXT_FILES";
const TXT_MORE_ACTIONS = "TXT_MORE_ACTIONS";
const TXT_BOOK_MARK_LINK = "TXT_BOOK_MARK_LINK";
const TXT_BLOCK_USER = "TXT_BLOCK_USER";
const TXT_MESSENGER_MEMBER = "TXT_MESSENGER_MEMBER";
const TXT_ADD_ADMIN_MEMBER = "TXT_ADD_ADMIN_MEMBER";
const TXT_REMOVER_MEMBER = "TXT_REMOVER_MEMBER";
const TXT_ADD_GROUP_ADMIN = "TXT_ADD_GROUP_ADMIN";
const TXT_ALL_MEMBER_LIST = "TXT_ALL_MEMBER_LIST";
const TXT_ADMIN_MEMBER_LIST = "TXT_ADMIN_MEMBER_LIST";
const TXT_CHANGE_CHANNEL = "TXT_CHANGE_CHANNEL";
const TXT_SAVE_CHANNEL_INFO = "TXT_SAVE_CHANNEL_INFO";
const TXT_CHANGE_NAME_INFO = "TXT_CHANGE_NAME_INFO";
const TXT_CHANGE_GROUP_NAME = "TXT_CHANGE_GROUP_NAME";
const TXT_CHANGE_GROUP_CHANGE_NAME = "TXT_CHANGE_GROUP_CHANGE_NAME";
const TXT_CHANGE_GROUP_NAME_INFO = "TXT_CHANGE_GROUP_NAME_INFO";
const TXT_DES_CONFIRM_LEAVE = "TXT_DES_CONFIRM_LEAVE";
const TXT_BUTTON_CHOOSE_ADMIN = "TXT_BUTTON_CHOOSE_ADMIN";
const TXT_BLOCK_SINGLE_GROUP = "TXT_BLOCK_SINGLE_GROUP";
const TXT_CONFIRM_REMOVE = "TXT_CONFIRM_REMOVE";
const TXT_CONFIRM_REMOVE_CONTENT = "TXT_CONFIRM_REMOVE_CONTENT";
const TXT_BUTTON_REMOVE = "TXT_BUTTON_REMOVE";
const TXT_UN_BLOCK_USER = "TXT_UN_BLOCK_USER";
const TXT_NO_NAME = "TXT_NO_NAME";
const TXT_SEE_CHANNEL_MEMBERS = "TXT_SEE_CHANNEL_MEMBERS";
const TXT_CHANGE_NAME_CHANNEL = "TXT_CHANGE_NAME_CHANNEL";
const TXT_CHANNEL_INFO = "TXT_CHANNEL_INFO";
const TXT_PLACEHODLER_CHANGE_CHANNEL_NAME = "TXT_PLACEHODLER_CHANGE_CHANNEL_NAME";
const TXT_REMOVE_MEMBER_CHANNEL = "TXT_REMOVE_MEMBER_CHANNEL";

// GroupInfo end

// Calling start

const TXT_CALLING = "TXT_CALLING";
const TXT_RINGING = "TXT_RINGING";
const TXT_GROUP_CALL = "TXT_GROUP_CALL";
const TXT_PERSONAL_CALL = "TXT_PERSONAL_CALL";
const TXT_MISSED_CALL = "TXT_MISSED_CALL";
const TXT_YOU = "TXT_YOU";
const TXT_CALL_AGAIN = "TXT_CALL_AGAIN";
const TXT_CALL_FINISH = "TXT_CALL_FINISH";
const TXT_REQUEST_DEVICE_FAIL = "TXT_REQUEST_DEVICE_FAIL";
const TXT_INTERNET_CONNECTION_FAILED = "TXT_INTERNET_CONNECTION_FAILED";
const TXT_CALL_FAILED = "TXT_CALL_FAILED";
const TXT_SETTING = "TXT_SETTING";
const TXT_DEVICE_NOT_FOUND = "TXT_DEVICE_NOT_FOUND";
const TXT_INPUT_DEVICE = "TXT_INPUT_DEVICE";
const TXT_OUTPUT_DEVICE = "TXT_OUTPUT_DEVICE";
const TXT_DONE = "TXT_DONE";
const TXT_IN_ANOTHER_CALL = "TXT_IN_ANOTHER_CALL";
const TXT_RECEIVER_IN_ANOTHER_CALL = "TXT_RECEIVER_IN_ANOTHER_CALL";
const TXT_CALL_ENDED = "TXT_CALL_ENDED";
const TXT_JOIN_CALL = "TXT_JOIN_CALL";
const TXT_CALL_REJECTED = "TXT_CALL_REJECTED";
const TXT_TURN_OFF_MIC = "TXT_TURN_OFF_MIC";
const TXT_MIC_TURNED_OFF = "TXT_MIC_TURNED_OFF";
const TXT_VIDEO_CALL_ENDED = "TXT_VIDEO_CALL_ENDED";
const TXT_CONNECTION_LOST = "TXT_CONNECTION_LOST";
const TXT_RECONNECTING = "TXT_RECONNECTING";
const TXT_FAILED_TO_RECONNECT = "TXT_FAILED_TO_RECONNECT";
const TXT_SHARE_SCREEN_MESSAGE = "TXT_SHARE_SCREEN_MESSAGE";
const TXT_START_CAPTURING = "TXT_START_CAPTURING";
const FM_CONFIRM_SHARE_SCREEN = "FM_CONFIRM_SHARE_SCREEN";
const TXT_SHARE_SCREEN_OPTIONS = "TXT_SHARE_SCREEN_OPTIONS";
const TXT_SHARE_SCREEN = "TXT_SHARE_SCREEN";
const TXT_SHARE_WINDOW = "TXT_SHARE_WINDOW";
const TXT_PIN_USER = "TXT_PIN_USER";
const TXT_UNPIN = "TXT_UNPIN";
const TXT_STOP_SHARING = "TXT_STOP_SHARING";
const TXT_CURRENTLY_SHARING = "TXT_CURRENTLY_SHARING";
const TXT_SHARING_TITLE = "TXT_SHARING_TITLE";
const FM_USER_BECAME_FOCUS_SHARING = "FM_USER_BECAME_FOCUS_SHARING";
const TXT_CURRENTLY_CRIPPLE = "TXT_CURRENTLY_CRIPPLE";
const TXT_CONFIRM_TITLE = "TXT_CONFIRM_TITLE";
const TXT_GROUP_CALL_INIT = "TXT_GROUP_CALL_INIT";
const TXT_CALL = "TXT_CALL";
const TXT_CANCEL_CALL = "TXT_CANCEL_CALL";
const TXT_ALL_MEMBERS = "TXT_ALL_MEMBERS";
const TXT_DELETE_ALL_MEMBERS = "TXT_DELETE_ALL_MEMBERS";
const TXT_REMOVE_MEMBER = "TXT_REMOVE_MEMBER";
const TXT_EDIT_CALL_INFO = "TXT_EDIT_CALL_INFO";
const TXT_RAISE_HAND = "TXT_RAISE_HAND";
const TXT_USER_IN_CALL_TITLE = "TXT_USER_IN_CALL_TITLE";
const TXT_COPY_LINK_CALL = "TXT_COPY_LINK_CALL";
const TXT_USER_IN_CALL = "TXT_USER_IN_CALL";
const TXT_USER_WAITING = "TXT_USER_WAITING";
const TXT_WAITING = "TXT_WAITING";
const TXT_CONFERENCE = "TXT_CONFERENCE";
const TXT_MEET_OBJ = "TXT_MEET_OBJ";

const FM_RECENT_CALL = "FM_RECENT_CALL";
const FM_RECENT_VIDEO_CALL = "FM_RECENT_VIDEO_CALL";
const FM_MISSED_CALL = "FM_MISSED_CALL";
const FM_MISSED_VIDEO_CALL = "FM_MISSED_VIDEO_CALL";
const FM_MISSED_CALL_SIDE_BAR = "FM_MISSED_CALL_SIDE_BAR";
const FM_MISSED_VIDEO_CALL_SIDE_BAR = "FM_MISSED_VIDEO_CALL_SIDE_BAR";
const FM_FOCUS_SHARING_CHANGED = "FM_FOCUS_SHARING_CHANGED";
const FM_STARTED_CALL = "FM_STARTED_CALL";
const FM_JOINED_CALL = "FM_JOINED_CALL";
const FM_LEAVE_CALL = "FM_LEAVE_CALL";

const OBJ_CALL_LENGTH = "OBJ_CALL_LENGTH";
const OBJ_INIT_GROUP_CALL = "OBJ_INIT_GROUP_CALL";

// Create third party token
const TXT_CREATE_THIRD_TOKEN = "TXT_CREATE_THIRD_TOKEN";
const TXT_WAIT_MINUTE = "TXT_WAIT_MINUTE";

export default {
  // List language code
  DEFAULT_LANG,

  // Namespace Key
  NS_COMMON,
  NS_LOGIN,
  NS_NOT_FOUND,
  NS_HOME,
  NS_HOME_CONVERSATION,
  NS_MANAGE_FILE,

  // Common start
  TXT_APP_NAME,
  TXT_PHONE_NUMBER,
  TXT_CONTINUE,
  TXT_ACTIVE,
  TXT_INACTIVE,
  TXT_OTHER,
  TXT_EATING,
  TXT_BEAUTY,
  TXT_SPORT,
  TXT_HEALTH,
  TXT_PET,
  TXT_REPAIR,
  TXT_OK,
  TXT_RETRY,
  TXT_CONTACT_PHONE,
  TXT_CONTACT_EMAIL,
  TXT_COMPANY_NAME,
  TXT_DELETE,
  TXT_REPLY,
  TXT_SURE_REMOVE,
  TXT_CONFIRM_REMOVE_PHOTO,
  TXT_SEARCH,
  TXT_ACCEPT,
  TXT_DENIED,
  TXT_CANCEL,
  TXT_CONFIRM,
  TXT_POPUP_DOCUMENT,
  TXT_POPUP_CAMERA,
  TXT_POPUP_GALLERY,
  TXT_POPUP_AUDIO,
  TXT_PLACEHOLDER,
  TXT_DEFAULT_USER_NAME,
  TXT_HOUR,
  TXT_MINUTE,
  TXT_SECOND,
  TXT_MONTH,
  TXT_DAY,
  TXT_YEAR,
  TXT_COPY,
  TXT_EDIT,
  TXT_CLOSE,
  TXT_CUSTOMIZE,
  TXT_RESET,
  TXT_SAVE,
  TXT_SESSION_EXPIRED,
  TXT_DOWNLOAD,
  TXT_YES,
  TXT_NO,
  TXT_CALL_ACCEPT,
  TXT_NO_INTERNET,
  TXT_HIDE,
  TXT_SERVER_ERROR,
  TXT_PASSCODE_TITLE,
  TXT_PASSCODE_SUBTITLE,
  TXT_RESTORE_PASSCODE_SUBTITLE,
  TXT_RESTORE_CONFIRM,
  TXT_BACKUP,
  TXT_RESTORE,
  TXT_BACKUP_INSTRUCTION,
  TXT_BACKUP_WARNING,
  TXT_PASSCODE_INSTRUCTION,
  TXT_CREATE_PASSCODE,
  TXT_BACKUP_SUCCESS,
  TXT_NO_BACKUP,
  TXT_RESTORE_ACTION,
  TXT_RESTORE_INSTRUCTION,
  TXT_BLOCKED_RESTORE,
  TXT_BLOCKED_RESTORE_CONTENT,
  TXT_GOT_IT,
  TXT_KEEP_CONNECT_INTERNET,
  TXT_NOT_CONNECT,
  TXT_NOT_CONNECT_INFO,
  TXT_RESTORE_SUCCESS,
  TXT_CONFIRM_LOGOUT_MASTER,
  TXT_CONTENT_LOGOUT_MASTER,
  TXT_CHOOSE_DEVICE,
  TXT_CHOOSE_DEVICE_CONFIRM,
  TXT_CHOOSE_DEVICE_SUCCESS,
  TXT_LOGOUT_LATER,
  TXT_CONTENT_LOGOUT_SLAVE,
  TXT_LOSE_MASTER_PERMISSION,
  TXT_BLOCK_CHANGE_DEVICE_ROLE,
  TXT_LOGOUT_MASTER_WITHOUT_SLAVE,
  TXT_CHECKING_RESTORE_DATA,

  FM_PASSCODE_BLOCKED,
  FM_RESTORE_SUBTITLE,
  FM_PASSCODE_WRONG,

  OBJ_ACC_STATUS,
  OBJ_SEND_MES_TYPE,
  OBJ_BACKUP_INFO,

  ARR_DAY_OF_WEEK,

  // Common end

  // Login start
  TXT_LOGIN,
  TXT_LOGOUT,
  TXT_RESTORE_DATA,
  TXT_RESEND_OTP,
  TXT_RESEND_SMS_OTP,
  TXT_SENT_OTP_TITLE,
  TXT_NAME_TITLE,
  TXT_SENT_SMS_OTP_TITLE,
  TXT_SENT_SMART_OTP_TITLE,
  TXT_REMEMBER_ME,
  TXT_OTP_ERROR_MESSAGE,
  TXT_OTP_LOCKED,
  TXT_OTP_LOCKED_FULL_MESSAGE,
  TXT_UNREGISTERED_MESSAGE,
  TXT_CHANGE_PHONE_NUMBER,
  TXT_TRY_AGAIN_LATTER,
  TXT_CHANGE_SMS_OTP,
  TXT_CHANGE_SMART_OTP,
  TXT_RESTORE_DATA_WARNING,
  TXT_RESTORE_DATA_INPROGRESS,
  TXT_RESTORE_DATA_ERROR,
  TXT_SENT_OTP_MESSAGE,
  TXT_SENT_SMS_OTP_MESSAGE,
  TXT_SENT_SMART_OTP_MESSAGE,
  TXT_OTP_EXPIRED,
  TXT_LIMIT_DEVICE,
  TXT_LOGIN_WELCOME_TITLE,
  TXT_LOGIN_WELCOME_SUBTITLE,

  FM_COUNTDOWN_OTP,
  FM_PHONE_CODE,
  // Login end

  // NotFound start
  TXT_PAGE_NOT_FOUND,
  TXT_PAGE_NOT_FOUND_DESCRIPTION,
  // NotFound end

  // Home start
  TXT_ACCOUNT,
  TXT_SERVERS,
  TXT_BRAND_SERVERS,
  TXT_CHATS,
  TXT_CONTACT,
  TXT_NOTIFICATION,
  TXT_NO_MESSAGE,
  TXT_ALL,
  TXT_CHANNEL,
  TXT_PERSONAL,
  TXT_GROUP,
  TXT_CHAT_SEARCH_PLACE_HOLDER,
  TXT_NO_NOTIFICATION,
  TXT_KEYWORDS,
  TXT_CLEAR_ALL,
  TXT_NOT_FOUND,
  TXT_NO_SEARCH_KEYWORDS,
  TXT_CONTACT_FOUND,
  TXT_GROUP_FOUND,
  TXT_CHANNEL_FOUND,
  TXT_MESSAGE_FOUND,
  TXT_ABOUT_US,
  TXT_INCOMING_CALL,
  TXT_INCOMING_VIDEO_CALL,
  TXT_INCOMING_CALL_DESC,
  TXT_GROUP_ITEM_OUTGOING_CALL,
  TXT_GROUP_ITEM_INCOMING_CALL,
  TXT_GROUP_CALLING,
  TXT_NO_MEMBER_CHOOSE,
  TXT_CONFERENCE,
  TXT_CHANGE_ROLE,
  TXT_CONFIRM_CHANGE_ROLE_TITLE,
  TXT_CONFIRM_CHANGE_ROLE_CONTENT,
  TXT_CHANGE_ROLE_OTP_TITLE,
  TXT_VERIFY,
  TXT_VIEW_MESSAGE_THREAD,
  TXT_VIEW_MORE,
  TXT_MEMBER_OVER_TOOLTIP,
  TXT_FLOW_MESSAGE,
  TXT_NO_UNSEEN_THREAD,
  TXT_REPLY_THREAD_MESSAGE,

  FM_WELCOME,
  FM_MENTION_NOTIFICATION,
  FM_MENTION_INBOX,
  FM_OTP_NOTIFICATION,
  FM_OWNER_NOTIFICATION,
  FM_INCOMING_CALL_TITLE,
  FM_INCOMING_VIDEO_CALL_TITLE,
  FM_INCOMING_GROUP_CALL_TITLE,
  FM_INCOMING_GROUP_VIDEO_CALL_TITLE,
  FM_OTHER_CALL_PARTICIPANT,
  FM_DOWNLOADED_CONTENT,
  FM_DOWNLOAD_SPEED,
  FM_CHANGE_ROLE_OTP_CONTENT,
  FM_NUMBER_THREAD_REPLY,
  FM_MEMBER,
  FM_NUMBER_FLOW_MESSAGE,
  UNSEEN_MESSAGE,
  FM_CHOOSE_MEMBER,

  OBJ_ACCOUNT_ME,
  OBJ_ACCOUNT_PROFILE,
  OBJ_EDIT_PROFILE,
  OBJ_ACCOUNT_LICENSE,
  OBJ_ACCOUNT_ABOUT,
  OBJ_ACCOUNT_DEVICE,
  OBJ_ADDING_CHAT,
  OBJ_ADDING_CONTACT,
  OBJ_DEVICE_TYPE_TEXT,
  // Home end

  // Conversation start
  TXT_PASTE,
  TXT_CHANGE_ALIAS,
  TXT_VIEW,
  TXT_PEOPLE,
  TXT_ADD_NEW_MEMBER,
  TXT_CHOOSE_NEW_ADMIN,
  TXT_MY_CONTACT,
  TXT_VIEW_POPUP,
  TXT_DELETE_POPUP,
  TXT_DELETE_FOR_YOU,
  TXT_STATIC_REACTION,
  TXT_CUSTOM_EMOJI,
  TXT_REACTIONS,
  TXT_THREAD,
  TXT_PUBLIC_CHANNEL,
  TXT_FILE_NOT_FOUND,
  TXT_NO_NAME,
  TXT_UN_BLOCK_USER,
  TXT_EDITTED,
  TXT_DELETE_CONTACT,
  TXT_CONTENT_DELETE_CONTACT,
  TXT_SEND_FILE_CONFIRM_TEXT,
  TXT_UPLOAD_FAILED,
  TXT_CONFIRM_SENDING_MEDIA,
  TXT_INPUT_CAPTION,
  TXT_INVITE_TO_SERVER,
  TXT_SENDING,
  TXT_SENT,
  TXT_RECEIVED,
  TXT_SEND_FAILED,
  TXT_INVITE_REJECTED,
  TXT_INVITE_DISMISSED,
  TXT_INVITE_ACCEPTED,
  TXT_SEEN,
  TXT_CONFERENCE_CALL,
  TXT_INACTIVE_USER,

  FM_LAST_MESSAGE_MINE,
  FM_CONFIRM_DETAIL,
  FM_CONFIRM_REMOVE,
  FM_FILE_SIZE_MEGA_BYTES,
  FM_INVITE_TO_SERVER,
  FM_SINGULAR_RESULT,
  FM_MANY_RESULTS,
  FM_REPLY_COUNT,
  FM_NEW_REPLY_COUNT,
  FM_SEEN_MEMBER,
  FM_CONFIRM_REMOVE_CHANNEL_MEMBER,

  CONFIRM_DELETE_MESSAGE,
  CONFIRM_DELETE_MESSAGE_CONTENT,
  // Conversation end

  // GroupInfo start
  TXT_VOICE_CALL,
  TXT_VIDEO_CALL,
  TXT_ADD,
  TXT_MUTE,
  TXT_UNMUTE,
  TXT_SEE_GROUP_MEMBERS,
  TXT_CHANGE_NAME_GROUP,
  TXT_MEMBERS,
  TXT_GROUP_INFO,
  TXT_GALLERY,
  TXT_SEARCH_IN_CONVERSATION,
  TXT_CHANGE_GROUP_PHOTO,
  TXT_END_TO_END_ENCRYPTION,
  TXT_PRIVACY,
  TXT_PERSONAL_PRIVACY,
  TXT_NO_MEDIA,
  TXT_CONTENT_NO_MEDIA,
  TXT_NO_FILE,
  TXT_CONTENT_NO_FILE,
  TXT_NO_LINK,
  TXT_CONTENT_NO_LINK,
  TXT_MEDIA,
  TXT_LINK,
  TXT_FILES,
  TXT_MORE_ACTIONS,
  TXT_BOOK_MARK_LINK,
  TXT_BLOCK_USER,
  TXT_MESSENGER_MEMBER,
  TXT_ADD_ADMIN_MEMBER,
  TXT_REMOVER_MEMBER,
  TXT_ADD_GROUP_ADMIN,
  TXT_ALL_MEMBER_LIST,
  TXT_ADMIN_MEMBER_LIST,
  TXT_CHANGE_CHANNEL,
  TXT_SAVE_CHANNEL_INFO,
  TXT_CHANGE_NAME_INFO,
  TXT_CHANGE_GROUP_NAME,
  TXT_CHANGE_GROUP_CHANGE_NAME,
  TXT_CHANGE_GROUP_NAME_INFO,
  TXT_DES_CONFIRM_LEAVE,
  TXT_BUTTON_CHOOSE_ADMIN,
  TXT_BLOCK_SINGLE_GROUP,
  TXT_CONFIRM_REMOVE,
  TXT_CONFIRM_REMOVE_CONTENT,
  TXT_BUTTON_REMOVE,
  TXT_SEE_CHANNEL_MEMBERS,
  TXT_CHANGE_NAME_CHANNEL,
  TXT_CHANNEL_INFO,
  TXT_PLACEHODLER_CHANGE_CHANNEL_NAME,
  TXT_REMOVE_MEMBER_CHANNEL,

  // GroupInfo end

  // Calling start

  NS_CALLING,

  TXT_CALLING,
  TXT_RINGING,
  TXT_GROUP_CALL,
  TXT_PERSONAL_CALL,
  TXT_MISSED_CALL,
  TXT_YOU,
  TXT_CALL_AGAIN,
  TXT_CALL_FINISH,
  TXT_CALL_FAILED,
  TXT_REQUEST_DEVICE_FAIL,
  TXT_INTERNET_CONNECTION_FAILED,
  TXT_SETTING,
  TXT_DEVICE_NOT_FOUND,
  TXT_INPUT_DEVICE,
  TXT_OUTPUT_DEVICE,
  TXT_DONE,
  TXT_IN_ANOTHER_CALL,
  TXT_RECEIVER_IN_ANOTHER_CALL,
  TXT_CALL_ENDED,
  TXT_JOIN_CALL,
  TXT_CALL_REJECTED,
  TXT_MIC_TURNED_OFF,
  TXT_TURN_OFF_MIC,
  TXT_VIDEO_CALL_ENDED,
  TXT_CONNECTION_LOST,
  TXT_RECONNECTING,
  TXT_FAILED_TO_RECONNECT,
  TXT_START_CAPTURING,
  FM_CONFIRM_SHARE_SCREEN,
  TXT_SHARE_SCREEN_MESSAGE,
  TXT_SHARE_SCREEN_OPTIONS,
  TXT_SHARE_SCREEN,
  TXT_SHARE_WINDOW,
  TXT_PIN_USER,
  TXT_UNPIN,
  TXT_STOP_SHARING,
  TXT_CURRENTLY_SHARING,
  TXT_SHARING_TITLE,
  FM_USER_BECAME_FOCUS_SHARING,
  TXT_CURRENTLY_CRIPPLE,
  TXT_CONFIRM_TITLE,
  TXT_GROUP_CALL_INIT,
  TXT_CALL,
  TXT_CANCEL_CALL,
  TXT_ALL_MEMBERS,
  TXT_DELETE_ALL_MEMBERS,
  TXT_REMOVE_MEMBER,
  TXT_EDIT_CALL_INFO,
  TXT_RAISE_HAND,
  TXT_USER_IN_CALL_TITLE,
  TXT_COPY_LINK_CALL,
  TXT_USER_IN_CALL,
  TXT_USER_WAITING,
  TXT_WAITING,
  TXT_MEET_OBJ,

  FM_RECENT_CALL,
  FM_RECENT_VIDEO_CALL,
  FM_MISSED_CALL,
  FM_MISSED_VIDEO_CALL,
  FM_MISSED_CALL_SIDE_BAR,
  FM_MISSED_VIDEO_CALL_SIDE_BAR,
  FM_FOCUS_SHARING_CHANGED,
  FM_STARTED_CALL,
  FM_JOINED_CALL,
  FM_LEAVE_CALL,

  OBJ_CALL_LENGTH,
  OBJ_INIT_GROUP_CALL,

  // Create third party token
  TXT_CREATE_THIRD_TOKEN,
  TXT_WAIT_MINUTE,
};
