import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppConstant, ImageConstant } from "const";

const usePreviewLink = props => {
  const { previewUrl, messageId } = props || {};
  const linkData = useSelector(state => state.conversationRedux.linkData);

  const [linkMetaData, setLinkMetaData] = useState({
    title: "",
    description: "",
    image: ImageConstant.NoFileImage,
    url: previewUrl,
  });

  const clickExternalLink = useCallback(
    event => {
      event.preventDefault();
      if (previewUrl) {
        window.electronEvent.emitEvent(AppConstant.TRIOS_EMIT_EVENT_KEY.openExternalLink, previewUrl);
      }
    },
    [previewUrl],
  );

  useEffect(() => {
    if (!Boolean(messageId && previewUrl)) return;

    if (linkData[previewUrl]) {
      const dataMessageLink = linkData[previewUrl].metaData;

      setLinkMetaData({
        ...dataMessageLink,
        image: dataMessageLink.image || ImageConstant.NoFileImage,
      });
    } else {
      window.electronEvent.emitEvent(AppConstant.TRIOS_EMIT_EVENT_KEY.getUrlMetaData, {
        url: previewUrl,
        messageId: messageId,
      });
    }
  }, [linkData, previewUrl, messageId]);

  return { linkMetaData, clickExternalLink };
};

export default usePreviewLink;
