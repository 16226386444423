import { createReducer, createActions } from "reduxsauce";
import { REDUX_STATE, requestReducerFunc, successReducerFunc } from "./redux-structure";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  synchronizeData: ["prefixKey"],
  executeQueue: [],
  systemSet: ["data"],
});

export const SystemTypes = Types;
export const SystemActions = Creators;
export default Creators;

/* ------------- Initial State ------------- */
const INITIAL_STATE = {
  ...REDUX_STATE,

  isSynchronizing: false, // true: Synch data when login or switch branch
  isSynchMessage: false, // true: Synch message periodic

  /**
   * New notification has 2 types:
   * 1. Invitation: {type: 2, branchId, ...other}
   * 2. Normal notification: { groupId, messageId, prefixKey }
   */
  newNotification: null,
  toast: {}, // { content, severity, modified }

  // TODO: Hotfix - show management file as a dialog
  isShowMnFile: false,
  seenCallHistoryTimestamp: 0, // timestamp seen call history
};

/* ------------- Selector ------------- */
export const SystemSelectors = {
  isSystemSynchronizing: ({ systemRedux }) => Boolean(systemRedux.isSynchronizing),
  getNewNotification: ({ systemRedux }) => systemRedux.newNotification,
  isShowMnFile: ({ systemRedux }) => systemRedux.isShowMnFile,
  seenCallHistoryTimestamp: ({ systemRedux }) => systemRedux.seenCallHistoryTimestamp,
};

/* ------------- Reducers ------------- */
const request = (state = INITIAL_STATE) => requestReducerFunc(state);

const set = (state = INITIAL_STATE, action) => successReducerFunc(state, action);

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.SYNCHRONIZE_DATA]: request,
  [Types.EXECUTE_QUEUE]: request,
  [Types.SYSTEM_SET]: set,
};

/* ------------- Hookup Reducers To Types ------------- */
export const SystemReducer = createReducer(INITIAL_STATE, HANDLERS);
