import { useCallback, useEffect, useRef } from "react";
import debounce from "lodash/debounce";
import { AppConstant } from "const";

const useLazyEffect = (callbackFun, deps = [], waitingMillisecond = AppConstant.DEBOUNCE_TIME) => {
  const cleanUp = useRef();
  const callbackRef = useRef();
  callbackRef.current = useCallback(callbackFun, deps);

  const lazyCallbackFunc = useCallback(
    debounce(() => {
      cleanUp.current = callbackRef.current?.();
    }, waitingMillisecond),
    [],
  );

  useEffect(() => {
    lazyCallbackFunc();
  }, deps);

  useEffect(() => {
    return () => {
      cleanUp.current = null;
    };
  }, []);
};

export default useLazyEffect;
