import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { FormatConstant } from "const";
import { InfiniteScroll } from "components";
import { getInteractor } from "services/local.service";
import StringFormat from "string-format";
import store, { ConversationActions, ConversationSelectors, RestoreSelectors } from "redux-store";
import { useConversationContext } from "../ConversationContext";
import { getDisplayMessage, handleNewMessageList } from "./ViewMode.helper";
import isEqual from "lodash/isEqual";
import MessageItem from "../MessageItem";
import { useCleanUpEffect, useLazyEffect } from "hooks";
import { createSelector } from "reselect";
import { checkCurrentGroup } from "utils/view.utils";
import ScrollTooltip from "components/ScrollTooltip";

const getDefaultViewPaging = () => ({
  isEnd: false,
  showMessage: [],
});
const PAGING_SIZE = 15;

const memoizedReduxState = createSelector(
  [
    ConversationSelectors.getSelectedGroupId,
    state => state.conversationRedux.scrollToChildId,
    RestoreSelectors.isSuccessRestore,
  ],
  (selectedGroupId, scrollToChildId, isSuccessRestore) => {
    return {
      selectedGroupId,
      scrollToChildId,
      isSuccessRestore,
    };
  },
);

const memoizedIncomingState = createSelector(
  [ConversationSelectors.getSelectedGroupId, state => state.conversationRedux.incomingMessages],

  (selectedGroupId, incomingMessages) => {
    const messageInGroup = incomingMessages[selectedGroupId];
    const messageList = messageInGroup ? Object.values(messageInGroup) : null;
    return Array.isArray(messageList) && messageList.length > 0 ? messageList : null;
  },
);

const ViewMode = () => {
  const classes = useStyles();
  const { isMounted } = useCleanUpEffect();
  const fetchingRef = useRef(false);

  const { groupDetail: groupDetailContext } = useConversationContext();
  const { selectedGroupId, scrollToChildId, isSuccessRestore } = useSelector(memoizedReduxState);
  const incomingMessages = useSelector(memoizedIncomingState);

  const [pagination, setPagination] = useState(getDefaultViewPaging());

  const handleGetMessages = async (groupDetail = groupDetailContext, newPagination) => {
    // Skip query if during get data from db
    if (fetchingRef.current) return;

    fetchingRef.current = true;
    newPagination = newPagination || pagination || getDefaultViewPaging();
    newPagination = await getMessageWithPagination(groupDetail, newPagination);

    if (isMounted() && checkCurrentGroup(groupDetail.id)) setPagination(newPagination);

    fetchingRef.current = false;
    return true;
  };

  const handleIncomingMessage = useCallback(async (incomingMessages, pagination, groupDetail) => {
    const newMessageList = await handleNewMessageList(incomingMessages, pagination.showMessage, groupDetail);

    if (isMounted() && checkCurrentGroup(groupDetail.id)) {
      setPagination({
        ...pagination,
        showMessage: newMessageList,
      });
    }
  }, []);

  const handleScroll2Message = async () => {
    const showMessage = pagination.showMessage || [];
    const scroll2Message =
      scrollToChildId && showMessage.length > 0
        ? await getInteractor().LocalMessageService.getMessage(scrollToChildId)
        : null;
    const isValidScrollMsgId = scroll2Message?.id && checkCurrentGroup(scroll2Message.groupId);
    if (!isValidScrollMsgId) return;

    const selectedMessage = showMessage.find(item => item.id === scrollToChildId);
    if (selectedMessage) {
      scrollToMessage(scrollToChildId);
    } else {
      // If not found the message, scroll to load older message
      const mainScroll = document.getElementById(CHAT_WRAPPER_ID);
      const scrollHeight = 0 - parseInt(mainScroll.scrollHeight);
      if (mainScroll) {
        mainScroll.scrollTo({
          top: scrollHeight,
          behavior: "smooth",
        });
        scrollToMessage(scrollToChildId);
      }
    }
  };

  useEffect(() => {
    // Set default view mode
    setPagination(getDefaultViewPaging);
    fetchingRef.current = false;

    if (selectedGroupId && groupDetailContext.id === selectedGroupId) {
      // Set default view mode
      setPagination(getDefaultViewPaging);
      handleGetMessages(groupDetailContext, getDefaultViewPaging());
    }
  }, [selectedGroupId, groupDetailContext]);

  useEffect(() => {
    if (isSuccessRestore) {
      getMessageWithPagination(
        groupDetailContext,
        getDefaultViewPaging(),
        pagination.showMessage.length > PAGING_SIZE ? pagination.showMessage.length : PAGING_SIZE,
      ).then(newPagination => {
        if (!isEqual(newPagination, pagination)) {
          setPagination(newPagination);
        }
      });
    }
  }, [isSuccessRestore]);

  useEffect(() => {
    if (incomingMessages) handleIncomingMessage(incomingMessages, pagination, groupDetailContext);
  }, [incomingMessages]);

  useLazyEffect(
    () => {
      handleScroll2Message();
    },
    [scrollToChildId, selectedGroupId, pagination],
    100,
  );

  return (
    <InfiniteScroll
      id={CHAT_WRAPPER_ID}
      className={classes.wrapChatItem}
      hasMore={!pagination.isEnd}
      isReverse={true}
      ScrollTooltip={ScrollTooltip}
      onScrollToBottom={handleGetMessages}
      onClickScrollButton={scrollToLastMessage}
    >
      {pagination.showMessage.map(messageItem => (
        <MessageItem key={messageItem.id} message={messageItem} />
      ))}
    </InfiniteScroll>
  );
};

ViewMode.propTypes = {};
ViewMode.defaultProps = {};

export default memo(ViewMode);

const CHAT_WRAPPER_ID = "scroll-wrapper-id";

let retry = null;
const getMessageWithPagination = async (groupDetail, newPagination, limit = PAGING_SIZE) => {
  if (retry === null) retry = 1;
  else if (retry >= 3) {
    console.log("========== Maximum retry =============");
    retry = null;
    return newPagination;
  }

  const lastMessage = newPagination.showMessage[newPagination.showMessage?.length - 1] || {};
  const records = await getInteractor().LocalMessageService.getGroupMessage(groupDetail.id, {
    limit,
    compareTime: lastMessage.created || Date.now(),
    isShowDeletingMessage: true,
  });

  newPagination = {
    ...newPagination,
    isEnd: records.length < limit,
    showMessage: await getDisplayMessage(newPagination.showMessage.concat(records), groupDetail),
  };

  const displayMessageLength = newPagination.showMessage.length;
  if (!newPagination.isEnd && displayMessageLength < PAGING_SIZE) {
    retry++;
    newPagination = await getMessageWithPagination(groupDetail, newPagination);
  }

  retry = null;
  return newPagination;
};

const scrollToMessage = scrollToChildId => {
  const elementId = StringFormat(FormatConstant.FM_CHAT_ITEM_ID, scrollToChildId);
  const scrollToEl = document.getElementById(elementId);
  if (scrollToEl) {
    scrollToEl.scrollIntoView({ block: "center" });

    store.dispatch(
      ConversationActions.conversationSet({
        scrollToChildId: null,
      }),
    );
  }
};

export const scrollToLastMessage = () => {
  const wrapper = document.getElementById(CHAT_WRAPPER_ID);
  if (wrapper) wrapper.scrollTop = 0;
};

const useStyles = makeStyles({
  wrapChatItem: {
    display: "flex",
    flexDirection: "column-reverse",
    height: "100%",
    padding: "0 22px",
    paddingBottom: 15,
  },
});
