import { useEffect, useRef, useState } from "react";
import { KeyConstant, SystemConstant } from "const";
import { getInteractor } from "services/local.service";
import { getFileList } from "pubsub/services/file.service";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { ConversationSelectors } from "redux-store";
import { StorageUtil } from "utils";

const memoizedIncomingState = createSelector(
  [
    ConversationSelectors.getSelectedGroupId,
    ConversationSelectors.getThreadingId,
    state => state.conversationRedux.incomingMessages,
  ],

  (selectedGroupId, threadingId, incomingMessages) => {
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID);
    const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID);

    const messageInGroup = incomingMessages[threadingId || selectedGroupId];
    const messageList = messageInGroup ? Object.values(messageInGroup) : null;

    if (Array.isArray(messageList) && messageList.length > 0) {
      const sendMessages = messageList.filter(
        item => item.senderId === accountId && item.senderDeviceId === deviceId && Boolean(item.modified),
      );

      return sendMessages;
    }

    return null;
  },
);

// Handle upload file status with tus
const useUploadFile = (prefixKey, attachmentId, groupId) => {
  const cleanUpRef = useRef(false);
  const sendLocalMessage = useSelector(memoizedIncomingState);

  const [uploadStatus, setUploadStatus] = useState(SystemConstant.UPLOAD_STATUS.inProgress);

  const handleSetUploadStatus = param => {
    if (cleanUpRef.current) return null;
    setUploadStatus(param);
  };

  const handleUploadStatus = async () => {
    let fileRecord = await getInteractor(prefixKey).LocalFileService.get(attachmentId);
    if (false === Boolean(fileRecord)) {
      const remoteList = (await getFileList(prefixKey, [attachmentId], groupId)) || [];
      fileRecord = remoteList[0];
    }

    if (fileRecord) handleSetUploadStatus(fileRecord.status);
    else handleSetUploadStatus(SystemConstant.UPLOAD_STATUS.failed);
  };

  useEffect(() => {
    handleUploadStatus();

    return () => {
      cleanUpRef.current = true;
    };
  }, []);

  useEffect(() => {
    if (false === (Array.isArray(sendLocalMessage) && sendLocalMessage.length > 0)) return;
    if (sendLocalMessage.findIndex(item => item.content.includes(attachmentId) && item.groupId === groupId) >= 0) {
      handleUploadStatus();
    }
  }, [sendLocalMessage]);

  return { uploadStatus };
};

export default useUploadFile;
