import { useEffect } from "react";
import { AppConstant } from "const";

// Listener click link and open it on browser
export const useHandleExternalLink = (cssSelectorId, dependency) => {
  const { addExternalLinkEvent, removeExternalLinkEvent } = useExternalLink();

  useEffect(() => {
    addExternalLinkEvent(cssSelectorId);

    return () => removeExternalLinkEvent(cssSelectorId);
  }, dependency);

  return null;
};

export const useExternalLink = () => {
  const clickExternalLink = event => {
    event.preventDefault();
    if (event.currentTarget?.href) {
      window.electronEvent.emitEvent(AppConstant.TRIOS_EMIT_EVENT_KEY.openExternalLink, event.currentTarget.href);
    }
  };

  const addExternalLinkEvent = cssSelectorId => {
    const elements = document.querySelectorAll(`#${cssSelectorId} a`);
    if (elements) {
      for (const element of elements) {
        element.addEventListener("click", clickExternalLink);
      }
    }
  };

  const removeExternalLinkEvent = cssSelectorId => {
    const elements = document.querySelectorAll(`#${cssSelectorId} a`);
    if (elements) {
      for (const element of elements) {
        element.removeEventListener("click", clickExternalLink);
      }
    }
  };

  return { addExternalLinkEvent, removeExternalLinkEvent };
};
