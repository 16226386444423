import React, { memo, useEffect, useState } from "react";
import { CardMedia, Link, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import clsx from "clsx";
import { FormatConstant } from "const";
import { getExternalLinkFromString } from "utils";
import StringFormat from "string-format";
import { useHandleExternalLink, usePreviewLink } from "hooks";

const DataLink = ({ data }) => {
  const [listDataLink, setListDataLink] = useState([]); // {link, senderName, isDivider, messageId}

  useEffect(() => {
    if (data) {
      const urlArray = getExternalLinkFromString(data.content).map(item => ({
        link: item,
        senderName: data.senderName,
        messageId: data.id,
      }));

      setListDataLink(urlArray);
    }
  }, [data]);

  return (
    <Stack spacing={2}>
      {listDataLink.map((linkData, index) => (
        <LinkItem key={index} previewUrl={linkData.link} messageId={linkData.messageId} />
      ))}
    </Stack>
  );
};

export default DataLink;

const LinkItem = memo(({ previewUrl, messageId }) => {
  const elementId = StringFormat(FormatConstant.FM_CHAT_ITEM_ID, messageId);
  useHandleExternalLink(elementId);
  const { linkMetaData } = usePreviewLink({ previewUrl, messageId });
  const classes = useStyles();

  return (
    <Box className={classes.userAvatarAndName} id={elementId}>
      <CardMedia component="img" className={classes.cover} src={linkMetaData.image} />
      <Box className={clsx(classes.customerDetailBox)}>
        <Box className={classes.boxBodyUserInfo}>
          <Link className={clsx("ellipsis", classes.linkData)} target="_blank" href={linkMetaData.url}>
            {linkMetaData.url}
          </Link>
        </Box>
        <Box className={classes.boxRating}>
          <Typography className={clsx("ellipsis", classes.customerDetail)}>{linkMetaData.title}</Typography>
        </Box>
        <Typography className={clsx("ellipsis", classes.typographyReview)}>{linkMetaData.senderName}</Typography>
      </Box>
    </Box>
  );
});

const useStyles = makeStyles(theme => ({
  cover: {
    width: 76,
    height: 76,
    borderRadius: 4,
    objectFit: "cover",
  },

  userAvatarAndName: {
    width: "100%",
    display: "flex",
    paddingTop: 12,
    margin: 0,
  },

  customerDetailBox: {
    width: "100%",
    marginLeft: 12,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  borderBottom: {
    borderBottom: "0.5px solid #E6E8EB",
  },

  customerDetail: {
    lineHeight: "18px",
    height: "fit-content",
    paddingTop: 3,
    color: theme.palette.black,
    fontWeight: 400,
    fontSize: 12,
  },

  typographyReview: {
    lineHeight: "14px",
    maxWidth: "fit-content",
    width: "100%",
    fontWeight: 600,
    fontSize: 10,
    color: theme.palette.grey[500],
  },

  linkData: {
    textDecoration: "none",
    fontSize: 15,
    fontWeight: 400,
    lineHeight: "20px",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
