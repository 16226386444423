import { call, put, select } from "redux-saga/effects";
import { remoteApiFactory } from "services";
import { convertString2JSON, toCamel } from "utils";
import { getInteractor, LocalKeyActionService } from "services/local.service";
import { formatPagingParams } from "./saga.helper";
import { ApiConstant, KeyConstant, SystemConstant } from "const";
import { SystemActions, SystemSelectors } from "redux-store";
import { StorageUtil } from "utils";
import { getConversation } from "pubsub/services/conversation.service";
import { getAllDeviceFromRemote } from "pubsub/services/device.service";
import { getBranchMapping, getRemoteBranchAccount, getRemoteBranches } from "pubsub/services/branch.service";

// Synch data when user open app
export function* synchronizeData(action) {
  console.log("synchronizeData");
  const prefixKey = action?.prefixKey || StorageUtil.getCurrentPrefixKey();
  const isSynchronizing = yield select(SystemSelectors.isSystemSynchronizing);
  if (isSynchronizing) return;

  yield put(
    SystemActions.systemSet({
      isSynchronizing: true,
    }),
  );
  try {
    yield call(getRemoteBranches, prefixKey);
    yield call(getRemoteBranchAccount, prefixKey);
    yield call(getBranchMapping);
    yield call(getAllDeviceFromRemote, prefixKey);
    yield call(getConversation, prefixKey);
    yield call(synchContact, prefixKey);
    yield call(synchKey, prefixKey);
    synchEmoji(prefixKey); // Low-priority, not need to waiting

    const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID, prefixKey);
    const deviceName = StorageUtil.getCommonKey(KeyConstant.KEY_DEVICE_NAME, prefixKey);
    const version = window.env?.APP_VERSION;
    const device = yield getInteractor(prefixKey).LocalDeviceService.get(deviceId);
    if (device) {
      const deviceOptions = convertString2JSON(device.options, {});
      let payload = deviceOptions.device_name !== deviceName ? { device_name: deviceName } : {};

      if (version && device.version !== version) {
        payload = { device_name: deviceName, ...payload, version: parseInt(version) };
      }

      if (Object.keys(payload).length > 0) {
        yield call(remoteApiFactory.getBranchApi(prefixKey).updateDevice, payload);
      }
    }
  } catch (error) {
    console.log(error);
  }

  // Update flag to know synchronize or not
  StorageUtil.setItem(KeyConstant.KEY_SYNCHRONIZE_APP, Date.now(), prefixKey);
  yield put(
    SystemActions.systemSet({
      isSynchronizing: false,
    }),
  );
}

const synchContact = async prefixKey => {
  // Synch current account info
  try {
    const accountId = StorageUtil.getItem(KeyConstant.KEY_ACCOUNT_ID, prefixKey);
    const accountResponse = await remoteApiFactory.getBranchApi(prefixKey).getAccountByIds({
      account_ids: [accountId],
    });

    if (accountResponse.status === ApiConstant.STT_OK && Array.isArray(accountResponse.data)) {
      await getInteractor(prefixKey).LocalAccountService.save(accountResponse.data);
    }
  } catch (error) {
    console.log(error);
  }

  // Synch contact
  try {
    const getContactResponse = await remoteApiFactory.getBranchApi(prefixKey).getContactList({
      ...formatPagingParams(),
    });

    if (getContactResponse.status === ApiConstant.STT_OK) {
      const responseData = getContactResponse.data.data;
      await getInteractor(prefixKey).LocalContactService.save(responseData);
    }
    if (
      StorageUtil.getItem(SystemConstant.KEY_ERROR_TIME_WARNING, prefixKey) &&
      Number(StorageUtil.getItem(SystemConstant.KEY_ERROR_TIME_WARNING, prefixKey)) <
        new Date().getTime() - 60000 * 60 * 24
    ) {
      StorageUtil.setItem(SystemConstant.KEY_ERROR_TIME_WARNING, new Date().getTime(), prefixKey);
    }
  } catch (error) {
    console.log(error);
  }
};

const synchKey = async prefixKey => {
  // Synch keys
  try {
    const deviceId = StorageUtil.getItem(KeyConstant.KEY_DEVICE_ID, prefixKey);
    const keys = await LocalKeyActionService.getAllKeys(prefixKey);
    while (StorageUtil.getItem(KeyConstant.KEY_FIRST_UPLOAD_F, prefixKey) !== true) {
      const currentDevice = await getInteractor(prefixKey).LocalDeviceService.get(deviceId);
      if (Boolean(currentDevice?.upload_key_f)) {
        // If device is uploaded key on server, update KEY_FIRST_UPLOAD_F
        StorageUtil.setItem(KeyConstant.KEY_FIRST_UPLOAD_F, true, prefixKey);
      } else {
        // Else, uploading keys to server
        const uploadResponse = await remoteApiFactory.getBranchApi(prefixKey).uploadKeys(keys);
        if ([ApiConstant.STT_OK, ApiConstant.STT_CONFLICT].includes(uploadResponse.status)) {
          StorageUtil.setItem(KeyConstant.KEY_FIRST_UPLOAD_F, true, prefixKey);
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
};

const synchEmoji = async prefixKey => {
  try {
    const limit = SystemConstant.EMOIJ_LIMIT_RECORD;
    const emoji = await getInteractor(prefixKey).LocalEmojiService.getLastRecord();
    const sinceTime = emoji ? emoji.created + 1 : 0;
    let page = 1;

    let response = await getEmojiServer(prefixKey, {
      sinceTime: sinceTime,
      offset: page * limit - limit,
    });
    while (response.status === ApiConstant.STT_OK && response.data?.limit === response.data.numberOfElements) {
      page++;
      response = await getEmojiServer(prefixKey, {
        sinceTime: sinceTime,
        offset: page * limit - limit,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

async function getEmojiServer(prefixKey, conditionJson = {}) {
  if (!prefixKey) prefixKey = StorageUtil.getCurrentPrefixKey();

  const params = formatPagingParams({
    // Default params
    orderBy: SystemConstant.EMOIJ_ORDER_BY_COLUMN,
    limit: SystemConstant.EMOIJ_LIMIT_RECORD,
    sinceTime: 0,
    offset: 0,

    // Overwrite
    ...conditionJson,
  });

  const response = await remoteApiFactory.getBranchApi(prefixKey).getEmoji(params);
  if (response.status === ApiConstant.STT_OK && response.data?.data) {
    await getInteractor(prefixKey).LocalEmojiService.save(response.data.data);
  }

  return toCamel(response);
}
